import { createContext, useContext, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { searchForServices, searchForServicesMultiQuery } from '../libs/algolia'
import { getCurrentPosition } from '../libs/geo'
import useSearchResult from '../hooks/useSearchResult'
import useSearchForm from '../hooks/useSearchForm'

const Search = createContext()

export const useSearch = () => useContext(Search)

export const SearchProvider = ({ children }) => {
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useSearchForm()
  const [result, setResult] = useSearchResult()
  const [currentLocation, setCurrentLocation] = useState(null)

  const loadServices = useCallback(
    async (
      query,
      zipcode,
      options = {
        enableHighAccuracy: true,
        timeout: 60000,
      },
    ) => {
      setLoading(true)
      let coordinates = null

      if (!currentLocation) {
        try {
          const { coords } = await getCurrentPosition(options)
          if (!coords) {
            coordinates = null
            setCurrentLocation('error')
          } else {
            coordinates = coords
            setCurrentLocation(coordinates)
          }
        } catch (error) {
          coordinates = null
          console.log(error)
          setCurrentLocation('error')
        }
      } else if (currentLocation === 'error') {
        coordinates = null
      } else {
        coordinates = currentLocation
      }

      try {
        if (options.typeFilters || options.page >= 1) {
          const response = await searchForServices(query, zipcode, coordinates, {
            facets: ['_tags'],
            ...options,
          })
          setResult(response, !options.tagFilters, !options.typeFilters)
        } else {
          const response = await searchForServicesMultiQuery(query, zipcode, coordinates, {
            facets: ['_tags'],
            ...options,
          })
          response.results[1].hits = response.results[0].hits.concat(response.results[1].hits)
          setResult(response.results[1], !options.tagFilters, !options.typeFilters)
        }
      } catch (error) {
        setResult({})
      }
      setLoading(false)
    },
    [currentLocation, setResult],
  )

  const fetchServices = useCallback(
    async (options = {}) => {
      const params = { ...form, ...options }
      const { tag, type, query, zipcode, ...rest } = params
      loadServices(query, zipcode, rest)
      setForm(params)
    },
    [form, setForm, loadServices],
  )

  const value = { ...result, loading, form, fetchServices, setCurrentLocation }
  return <Search.Provider value={value}>{children}</Search.Provider>
}

SearchProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Search
