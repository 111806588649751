import algoliasearch from 'algoliasearch'
import { zipcodeToCoordinates, getStateFromZipcode } from './zipcodes'
import { getStateFromCoords } from './geo'

const VITE_ALGOLIA_APP_ID = import.meta.env.VITE_ALGOLIA_APP_ID
const VITE_ALGOLIA_SEARCH_KEY = import.meta.env.VITE_ALGOLIA_SEARCH_KEY

const DEFAULT_PARAMS = {
  aroundLatLngViaIP: true,
  getRankingInfo: true,
  aroundRadius: 'all',
  sortBy: 'by_distance',
}

const DEFAULT_VIRTUAL_PARAMS = {
  aroundLatLngViaIP: false,
  getRankingInfo: true,
}

const client = algoliasearch(VITE_ALGOLIA_APP_ID, VITE_ALGOLIA_SEARCH_KEY)

const locations = client.initIndex('locations')

const networkServices = client.initIndex('network_services')

const INDEXES = {
  by_name: client.initIndex('services_by_name_asc'),
  by_distance: client.initIndex('services'),
  by_price_asc: client.initIndex('services_by_price_asc'),
  by_price_desc: client.initIndex('services_by_price_desc'),
  by_organization: client.initIndex('services_by_organization'),
  by_landings: client.initIndex('services_by_price_desc'),
}

const INDEXES_BY_NAME = {
  by_name: 'services_by_name_asc',
  by_distance: 'services',
  by_price_asc: 'services_by_price_asc',
  by_price_desc: 'services_by_price_desc',
  by_organization: 'services_by_organization',
  by_landings: 'services_by_price_desc',
}

const getParams = async (zipcode, coordinates, options) => {
  const params = { ...DEFAULT_PARAMS, ...options }
  if (coordinates) {
    params.state = await getStateFromCoords(coordinates.latitude, coordinates.longitude)
    params.aroundLatLngViaIP = false
    params.aroundLatLng = `${coordinates.latitude},${coordinates.longitude}`
  }

  if (zipcode) {
    params.state = getStateFromZipcode(zipcode)
    const coords = await zipcodeToCoordinates(zipcode)

    if (coords) {
      params.aroundLatLngViaIP = false
      params.aroundLatLng = coords
    }
  }

  if (options.typeFilters) {
    switch (options.typeFilters[0]) {
      case 'virtual':
        params.filters = params.state
          ? `virtual:true AND statesLicensed:${params.state}`
          : `virtual:true`
        break
      case 'mobile':
        params.filters = `mobile:true`
        break
      case 'inPerson':
        params.filters = `inPerson:true`
        break
      default:
        break
    }
  }
  delete params.typeFilters
  return params
}

export const searchForServices = async (query = '', zipcode, coordinates, options = {}) => {
  const { sortBy, ...params } = await getParams(zipcode, coordinates, options)
  delete params.state
  return INDEXES[sortBy].search(query, params)
}

export const searchForServicesMultiQuery = async (
  query = '',
  zipcode,
  coordinates,
  options = {},
) => {
  const { sortBy, ...params } = await getParams(zipcode, coordinates, options)
  const VIRTUAL_PARAMS = {
    ...DEFAULT_VIRTUAL_PARAMS,
    filters: `virtual:true AND statesLicensed:${params.state}`,
    tagFilters: params.tagFilters,
  }
  delete params.state

  if (!params.tagFilters) {
    delete params.tagFilters
    delete VIRTUAL_PARAMS.tagFilters
  }

  if (!params.filters) {
    params.filters = `virtual:false`
  }

  const queries = [
    {
      indexName: 'services',
      query,
      type: 'default',
      params: VIRTUAL_PARAMS,
    },
    {
      indexName: INDEXES_BY_NAME[sortBy],
      query,
      type: 'default',
      params,
    },
  ]
  return client.multipleQueries(queries)
}

export const searchForNetworkServices = async (query = '', orgId, options) => {
  // Id for 'mishe'
  const stagingMisheId = 'qwGk5c8R6wfOvVjh6SJ7'
  // Id for 'savings plan'
  const prodMisheId = 'UUrfH2FiTx2LjkdzUhnP'

  const { sortBy, ...params } = await getParams(null, null, options)
  delete params.state

  return networkServices.search(query, {
    ...params,
    filters: `(networkId:${stagingMisheId} OR networkId:${prodMisheId}) AND organizationId:${orgId}`,
    hitsPerPage: 6,
  })

  // return networkServices.search(query, {
  //   filters: `(networkId:${stagingMisheId} OR networkId:${prodMisheId} AND organizationId:${orgId})`
  // })
}

export const searchForLandingsServices = async (
  query,
  zipcode,
  coordinates = null,
  options = {},
) => {
  const { sortBy, ...params } = await getParams(zipcode, coordinates, options)
  delete params.state
  return INDEXES[sortBy].search(query, params)
}

export const searchForLocations = async (query, zipcode, coordinates = null, options = {}) => {
  const { sortBy, ...params } = await getParams(zipcode, coordinates, options)
  delete params.state
  return locations.search(query, params)
}

export default client
