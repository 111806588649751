import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { useVouchers } from '../context/Vouchers'
import ProviderVouchersRow from '../components/ProviderVouchersRow'
import CircularProgress from '@mui/material/CircularProgress'

const ProviderVouchersRows = () => {
  const { vouchers, vouchersLoading } = useVouchers()

  if (vouchers.length === 0)
    return (
      <TableRow>
        <TableCell colSpan={9}>
          No results found for your search.
          <br /> Try searching for order/voucher ID or consumer&apos;s last name. You can also
          search for consumer&apos;s birth date.
        </TableCell>
      </TableRow>
    )
  return vouchersLoading ? (
    <TableRow>
      <TableCell align='center' colSpan={8}>
        <CircularProgress />
      </TableCell>
    </TableRow>
  ) : (
    vouchers.map(each => (
      <ProviderVouchersRow key={each.uid} row={each} subVouchers={each.subVouchers} />
    ))
  )
}

export default ProviderVouchersRows
