import { cloneElement } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import useToggleOpen from '../hooks/useToggleOpen'
import Modal from '../stories/Modal'
import Heading from '../stories/Heading'
import Divider from '../stories/Divider'
import NavLink from '../stories/NavLink'
import Button from '../stories/Button'
import Typography from '../stories/Typography'

import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

const GeneralModal = ({
  buttonText = '...',
  headerText = 'More Info',
  customButton = null,
  footerText = '',
  customFooter = null,
  maxWidth,
  children,
}) => {
  const [anchorEl, handleClick, handleClose] = useToggleOpen(null)
  const open = Boolean(anchorEl)

  const addOnClickToCustomButton = btn => {
    return cloneElement(btn, {
      onClick: handleClick,
    })
  }

  return (
    <>
      {customButton ? (
        addOnClickToCustomButton(customButton)
      ) : (
        <NavLink type='button' onClick={handleClick} style={{ textDecoration: 'underline' }}>
          {buttonText}
        </NavLink>
      )}
      <Modal maxWidth={maxWidth || 'sm'} open={open} anchorEl={anchorEl} onClose={handleClose}>
        <Box>
          <Heading variant='h3' weight='bold' align='center'>
            {headerText}
          </Heading>
          <Divider spacing='vertical' />
          {children}
        </Box>
        <Box mt={4}>
          {customFooter || (
            <Typography variant='body2' align='center'>
              {footerText}
            </Typography>
          )}
        </Box>
        <Box mt={4}>
          <Button size='medium' variant='contained' onClick={handleClose}>
            close
          </Button>
        </Box>
      </Modal>
    </>
  )
}

GeneralModal.propTypes = {
  buttonText: PropTypes.string,
  headerText: PropTypes.string,
  customButton: PropTypes.node,
  customFooter: PropTypes.node,
  footerText: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export const GeneralModalNew = ({
  open,
  handleClose,
  title,
  content,
  primaryAction,
  secondaryAction,
}) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        <Typography variant='body1' style={{ whiteSpace: 'pre-line' }} paragraph>
          {content}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant='outlined'
          onClick={secondaryAction.action}
          color='primary'
          sx={{
            width: '50%',
          }}
        >
          {secondaryAction.label}
        </Button>
        <Button
          onClick={primaryAction.action}
          color='primary'
          autoFocus
          sx={{
            width: '50%',
          }}
        >
          {primaryAction.label}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

GeneralModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  primaryAction: PropTypes.shape({
    label: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
  }).isRequired,
  secondaryAction: PropTypes.shape({
    label: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
  }).isRequired,
}

export default GeneralModal
