import { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import useToggleOpen from '../hooks/useToggleOpen'

const AddNoteModal = createContext()

export const useNotesModal = () => useContext(AddNoteModal)

const AddNoteModalProvider = ({ children }) => {
  const [noteType, setNoteType] = useState(null)
  const [anchorEl, handleClick, handleClose] = useToggleOpen(null)
  const open = Boolean(anchorEl)

  const value = { open, noteType, handleClick, handleClose, setNoteType }
  return <AddNoteModal.Provider value={value}>{children}</AddNoteModal.Provider>
}

AddNoteModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AddNoteModalProvider
