import { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Collapse from '@mui/material/Collapse'
import QRCode from 'qrcode.react'
import { Divider, Grid } from '@mui/material'
import Typography from '../stories/Typography'
import RedeemVoucherButton from '../containers/RedeemVoucherButton'
import ViewVoucherDetailsButton from '../containers/ViewVoucherDetailsButton'
import GenerateSuperBillButton from '../containers/GenerateSuperBillButton'
import Chip from './VoucherChip'
import { Toggle } from '../hooks/useToggleOpen'
import IconButton from '../stories/IconButton'
import Icon from '../stories/Icons'
import WarningText from '../stories/WarningText'
import Link from '../stories/Link'
import Button from '../stories/Button'
import OrderChatView from './OrderChatView'
import { formatBirthdayForUI } from '../libs/formatters'
import AddToCalender from './AddToCalender'
import { checkIfDate } from '../libs/validators'

const ProviderVouchersRow = ({ row, subVouchers }) => {
  const [addToCalenderOpen, setAddToCalenderOpen] = useState(false)

  const onClickAddToCalender = () => {
    setAddToCalenderOpen(!addToCalenderOpen)
  }
  // This is for the SubVoucher Query, since we have no other reference to which subvouchers should be shown

  const dateFormatOptions = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }

  return (
    <Toggle>
      {({ open, handleClick, handleClose }) => {
        return (
          <>
            <TableRow>
              <TableCell align='center'>
                <IconButton
                  aria-label={open ? 'close row' : 'expand row'}
                  size='small'
                  onClick={open ? handleClose : handleClick}
                >
                  {open ? <Icon name='closeArrow' /> : <Icon name='openArrow' />}
                </IconButton>
              </TableCell>
              <TableCell>
                <Typography variant='body2'>
                  {[row.customer.firstname, row.customer.lastname].join(' ').trim() || '---'}
                </Typography>
                <Typography variant='body2' color='textSecondary'>
                  {row.customer.email || '---'}
                </Typography>
              </TableCell>
              <TableCell>{row?.service?.name || '---'}</TableCell>
              <TableCell>
                {row?.appointmentDate && typeof row.appointmentDate === 'object'
                  ? row.appointmentDate.toDate()?.toLocaleString()
                  : '---'}
              </TableCell>
              <TableCell>{row?.date?.toLocaleString('en-US', dateFormatOptions)}</TableCell>
              <TableCell>{row?.code}</TableCell>
              <TableCell align='center'>
                {row?.bundle ? <Chip label='bundle' /> : <Chip label={row.status} />}
              </TableCell>
              <TableCell align='center'>
                {!row.bundle && (
                  <>
                    {row.status === 'purchased' && <RedeemVoucherButton voucher={row} />}
                    {row.status === 'cancelled' && <WarningText type='error'>---</WarningText>}
                    {row.status === 'redeemed' && <WarningText type='success'>---</WarningText>}
                  </>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              {!row.bundle && <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} />}
              <TableCell style={{ padding: '0px' }} colSpan={row.bundle ? 8 : 4}>
                {row?.bundle && (
                  <Collapse in={open} timeout='auto' unmountOnExit>
                    <Box>
                      <Box
                        width='100%'
                        display='flex'
                        padding='8px'
                        borderBottom='1px solid #ddd'
                        alignItems='center'
                      >
                        <Box>
                          <OrderChatView
                            order={row}
                            chatID={row?.id || row?.uid}
                            color='#7004c6'
                            variant='bundle'
                          />
                        </Box>
                      </Box>
                      <Grid container>
                        <Grid item xs={3}>
                          <Box padding='10px' bgcolor='#f6eeff'>
                            <Typography variant='body2' weight='medium'>
                              Service
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={3}>
                          <Box padding='10px' bgcolor='#f6eeff'>
                            <Typography variant='body2' weight='medium'>
                              Appt Date
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={3}>
                          <Box padding='10px' bgcolor='#f6eeff'>
                            <Typography variant='body2' weight='medium'>
                              Voucher ID
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={2}>
                          <Box padding='10px' bgcolor='#f6eeff'>
                            <Typography variant='body2' weight='medium' align='center'>
                              Status
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={1}>
                          <Box padding='10px' bgcolor='#f6eeff'>
                            <Typography variant='body2' weight='medium' align='center'>
                              Redeem
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        {subVouchers.length > 0 &&
                          subVouchers.map(subVoucher => (
                            <Fragment key={subVoucher.id}>
                              <Grid item xs={3}>
                                <Box
                                  bgcolor='#faf5ff'
                                  padding='10px'
                                  height='100%'
                                  display='flex'
                                  alignItems='center'
                                  justifyContent='flex-start'
                                >
                                  {subVoucher?.service?.name || '---'}
                                </Box>
                              </Grid>
                              <Grid item xs={1}>
                                <Box
                                  bgcolor='#faf5ff'
                                  padding='10px'
                                  height='100%'
                                  display='flex'
                                  alignItems='center'
                                  justifyContent='flex-start'
                                >
                                  {subVoucher?.appointmentDate &&
                                  typeof subVoucher.appointmentDate === 'object'
                                    ? subVoucher.appointmentDate.toDate().toLocaleString()
                                    : '---'}
                                </Box>
                              </Grid>
                              <Grid item xs={2}>
                                <Box
                                  bgcolor='#faf5ff'
                                  padding='10px'
                                  height='100%'
                                  display='flex'
                                  alignItems='center'
                                  justifyContent='flex-start'
                                >
                                  <ViewVoucherDetailsButton voucher={subVoucher} />
                                  {checkIfDate(subVoucher?.appointmentDate) && (
                                    <>
                                      <Button
                                        size='small'
                                        variant='outlined'
                                        onClick={onClickAddToCalender}
                                      >
                                        <Typography variant='body2' family='poppins'>
                                          Add To Calender
                                        </Typography>
                                      </Button>
                                      <AddToCalender
                                        calenderEvent={{
                                          title: subVoucher?.service?.name || '',
                                          description: `Voucher Link:  ${window.location.origin}/dashboard?vc=${subVoucher.code}`,
                                          start: subVoucher?.appointmentDate
                                            ?.toDate()
                                            ?.toISOString(),
                                          appointmentDate: subVoucher.appointmentDate,
                                          duration: [1, 'hour'],
                                        }}
                                        open={addToCalenderOpen}
                                        onClose={onClickAddToCalender}
                                      />
                                    </>
                                  )}
                                </Box>
                              </Grid>
                              <Grid item xs={3}>
                                <Box
                                  bgcolor='#faf5ff'
                                  padding='10px'
                                  height='100%'
                                  display='flex'
                                  alignItems='center'
                                  justifyContent='flex-start'
                                >
                                  {subVoucher.code}
                                </Box>
                              </Grid>
                              <Grid item xs={2} align='center'>
                                <Box
                                  bgcolor='#faf5ff'
                                  padding='10px'
                                  height='100%'
                                  display='flex'
                                  alignItems='center'
                                  justifyContent='center'
                                >
                                  <Chip
                                    label={subVoucher.status}
                                    style={{
                                      margin: '0px',
                                    }}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={1} align='center'>
                                <Box
                                  bgcolor='#faf5ff'
                                  padding='10px'
                                  height='100%'
                                  display='flex'
                                  alignItems='center'
                                  justifyContent='center'
                                >
                                  {subVoucher.status === 'purchased' && (
                                    <RedeemVoucherButton voucher={row} subVoucher={subVoucher} />
                                  )}
                                  {subVoucher.status === 'cancelled' && (
                                    <WarningText type='error'>---</WarningText>
                                  )}
                                  {subVoucher.status === 'redeemed' && (
                                    <WarningText type='success'>---</WarningText>
                                  )}
                                </Box>
                              </Grid>
                            </Fragment>
                          ))}
                        {/* Pete this is the idea behind adding services to bundles / converting vouchers to bundles */}
                        {/* <Grid item sm={12}>
                        <Box
                          padding='10px'
                          bgcolor='#f6eeff'
                          display='flex'
                          alignItems='center'
                          style={{
                            justifyContent: 'space-between'
                          }}
                        >
                          <Box>
                            <Typography variant='body2' weight='medium'>
                              Add more services to this bundle
                            </Typography>
                          </Box>
                          <Box>
                            <Button
                              variant='outlined'
                              color='primary'
                              size='small'
                              onClick={() => {
                                console.log('Add services to bundle')
                              }}
                            >
                              Add Services
                            </Button>
                          </Box>
                        </Box>
                      </Grid> */}
                      </Grid>
                    </Box>
                  </Collapse>
                )}
                {!row.bundle && (
                  <Collapse in={open} timeout='auto' unmountOnExit>
                    <Box display='flex' alignItems='center' my={2}>
                      <Icon name='account' fontSize='small' color='primary' />
                      <Box fontWeight={500} ml={2}>
                        Gender:
                      </Box>
                      <Box ml={1} mr={2}>
                        {row.customer.gender}
                      </Box>
                      <span>|</span>
                      <Box fontWeight={500} ml={2}>
                        Phone:
                      </Box>
                      <Box ml={1} mr={2}>
                        {row.customer.phone}
                      </Box>
                      <span>|</span>
                      <Box fontWeight={500} ml={2}>
                        DOB:
                      </Box>
                      <Box ml={1} mr={3}>
                        {formatBirthdayForUI(row.customer.birthday || row.customer.dob)}
                      </Box>
                    </Box>
                    <Box display='flex' alignItems='center' my={2}>
                      <Icon name='price' fontSize='small' color='primary' />
                      <Box fontWeight={500} ml={2}>
                        Order:
                      </Box>
                      <Box ml={1} mr={2}>
                        {row.orderId}
                      </Box>
                    </Box>
                    <Box display='flex' alignItems='center' my={2}>
                      <Box fontWeight={500} ml={2}>
                        Notes
                      </Box>
                      <Box ml={2}>
                        <ViewVoucherDetailsButton voucher={row} />
                      </Box>
                      {checkIfDate(row?.appointmentDate) && (
                        <>
                          <Box ml={3}>
                            <Button size='small' variant='outlined' onClick={onClickAddToCalender}>
                              <Typography variant='body1' family='poppins'>
                                Add To Calender
                              </Typography>
                            </Button>
                          </Box>
                          <AddToCalender
                            calenderEvent={{
                              title: row.service.name,
                              description: `Voucher Link:  ${window.location.origin}/dashboard?vc=${row.code}`,
                              start: row?.appointmentDate?.toDate()?.toISOString(),
                              appointmentDate: row.appointmentDate,
                              duration: [1, 'hour'],
                            }}
                            open={addToCalenderOpen}
                            onClose={onClickAddToCalender}
                          />
                        </>
                      )}
                    </Box>
                    {(row.telehealthLink || row.organization?.telehealthLink) && (
                      <Box display='flex' alignItems='center' my={2}>
                        <Box fontWeight={500} ml={2}>
                          Video Call Room:
                        </Box>
                        <Box ml={1} mr={2}>
                          <Link
                            to={row.telehealthLink || row.organization?.telehealthLink}
                            type='external'
                            display='inline'
                            variant='unstyled'
                            size='small'
                            target='_blank'
                            style={{
                              padding: '5px 10px',
                              borderRadius: '5px',
                              backgroundColor: '#662D91',
                              color: '#fff',
                              boxShadow: '1px 1px 5px 0px #662D91',
                            }}
                          >
                            Join External Call
                          </Link>
                        </Box>
                      </Box>
                    )}
                    <Box display='flex' alignItems='center' my={2}>
                      <Box fontWeight={500} ml={2}>
                        Video Call Room:
                      </Box>
                      <Box ml={1} mr={2}>
                        <Link
                          to={`/videochat?id=${row.uid}`}
                          type='internal'
                          display='inline'
                          variant='unstyled'
                          size='small'
                          target='_blank'
                          style={{
                            padding: '5px 10px',
                            borderRadius: '5px',
                            backgroundColor: '#662D91',
                            color: '#fff',
                            boxShadow: '1px 1px 5px 0px #662D91',
                          }}
                        >
                          Join Mishe Video Call
                        </Link>
                      </Box>
                    </Box>
                    {row.status === 'redeemed' && (
                      <Box display='flex' alignItems='center' my={2}>
                        <Box fontWeight={500} ml={2}>
                          Superbill:
                        </Box>
                        <Box fontWeight={500} ml={2}>
                          <GenerateSuperBillButton
                            voucher={row}
                            disabled={row.status !== 'redeemed'}
                          >
                            Download PDF
                          </GenerateSuperBillButton>
                        </Box>
                      </Box>
                    )}
                  </Collapse>
                )}
              </TableCell>
              {!row.bundle && (
                <>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2} align='right'>
                    <Collapse in={open} timeout='auto' unmountOnExit>
                      {row.status === 'purchased' && (
                        <>
                          <Box style={{ marginTop: 10, marginBottom: 10 }}>
                            <QRCode
                              value={`${window.location.origin}/dashboard?claim=${row.code}`}
                            />
                          </Box>
                        </>
                      )}
                      {row.status === 'cancelled' && (
                        <>
                          <Box fontWeight={500} mb={2}>
                            Cancelled at:
                          </Box>
                          <Box>{row.cancelledAt?.toLocaleString()}</Box>
                        </>
                      )}
                      {row.status === 'redeemed' && (
                        <>
                          <Box>Redeemed at:</Box>
                          <Box>{row.redeemedAt?.toLocaleString()}</Box>
                        </>
                      )}
                    </Collapse>
                  </TableCell>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} />
                </>
              )}
            </TableRow>
          </>
        )
      }}
    </Toggle>
  )
}

ProviderVouchersRow.propTypes = {
  row: PropTypes.shape({
    bundle: PropTypes.bool,
    uid: PropTypes.string,
    date: PropTypes.instanceOf(Date),
    status: PropTypes.string,
    code: PropTypes.string,
    appointmentDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    orderId: PropTypes.string,
    cancelledAt: PropTypes.instanceOf(Date),
    redeemedAt: PropTypes.instanceOf(Date),
    telehealthLink: PropTypes.string,
    service: PropTypes.shape({
      name: PropTypes.string,
      price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    organization: PropTypes.shape({
      name: PropTypes.string,
      telehealthLink: PropTypes.string,
      id: PropTypes.string,
    }),
    customer: PropTypes.shape({
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      email: PropTypes.string,
      gender: PropTypes.string,
      phone: PropTypes.string,
      birthday: PropTypes.string,
    }),
  }),
}

export default ProviderVouchersRow
