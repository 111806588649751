import { formatDOBForIntercom, formatPhoneNumberForAuth } from '../libs/formatters'

// Intercom helper functions
export function checkFields(uid, email, hash, profileData) {
  const config = {
    api_base: 'https://api-iam.intercom.io',
    app_id: 'kgysbru8',
    email: `${email}`,
    user_id: `${uid}`,
    user_hash: `${hash}`,
  }

  if (profileData.firstname) {
    config.name = `${profileData.firstname} ${profileData.lastname || ''}` // Full name
  }

  if (profileData.phone) {
    config.phone = `${formatPhoneNumberForAuth(profileData.phone) || profileData.phone || ''}` // Phone number
  }

  if (profileData.gender) {
    config.gender = `${profileData.gender}`
  }

  if (profileData.birthday) {
    config.dob = formatDOBForIntercom(profileData?.birthday || profileData?.dob) || ''
    console.log('  -- dob', config.dob)
  }

  if (profileData?.address) {
    if (profileData.address?.street) {
      config['Address 1'] = `${profileData.address.street}`
    }
    if (profileData.address?.street2) {
      config['Address 2'] = `${profileData.address.street2}`
    }
    if (profileData.address?.city) {
      config.City = `${profileData.address.city}`
    }
    if (profileData.address?.state) {
      config.State = `${profileData.address.state}`
    }
    if (profileData.address?.zip) {
      config['Zip code'] = `${profileData.address.zip}`
    }
  }

  return config
}
