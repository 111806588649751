import { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import DateRangeIcon from '@mui/icons-material/DateRange'
import { DatePicker } from '@mui/x-date-pickers'
import { GENDER_OPTIONS } from '../libs/validators'
import PhoneField from '../stories/PhoneInput'
import SelectField from '../stories/Select'
import TextField from '../stories/TextField'
import Button from '../stories/Button'
import Link from '../stories/Link'
import Icon from '../stories/Icons'

const SignUpForm = ({ onSubmit, errors = {} }) => {
  const [selectedDate, setSelectedDate] = useState(null)
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)

  const handleDateChange = date => {
    setSelectedDate(date)
  }

  return (
    <form title='sign-up-form' onSubmit={onSubmit}>
      <TextField
        required
        name='firstname'
        label='First name'
        display='standard'
        inputProps={{
          title: 'firstname',
        }}
        helperText={errors.firstname}
      />
      <TextField
        required
        name='lastname'
        label='Last name'
        display='standard'
        inputProps={{
          title: 'lastname',
        }}
        helperText={errors.lastname}
      />
      <TextField
        required
        name='email'
        type='email'
        label='Email address'
        display='standard'
        inputProps={{
          title: 'email',
        }}
        helperText={errors.email}
      />
      <PhoneField
        required
        name='phone'
        label='Phone number'
        display='standard'
        inputProps={{
          title: 'phone',
        }}
        helperText={errors.phone}
      />
      <Box
        position='relative'
      >
        <TextField
          required
          name='password'
          type={passwordVisible ? 'text' : 'password'}
          label='Password'
          display='standard'
          inputProps={{
            title: 'password',
          }}
          helperText={errors.password}
        />
        <Box
          position='absolute'
          right={0}
          top={0}
          bottom={0}
          mt={2}
          mb={2}
        >
          <Button
            variant='icon'
            onClick={() => setPasswordVisible(!passwordVisible)}
          >
            <Icon
              color='primary'
              name={passwordVisible ? 'visibility' : 'visibilityOff'}
            />
          </Button>
        </Box>
      </Box>
      <Box
        position='relative'
      >
        <TextField
          required
          name='passwordConfirmation'
          type={confirmPasswordVisible ? 'text' : 'password'}
          label='Password Confirmation'
          display='standard'
          inputProps={{
            title: 'password-confirmation',
          }}
          helperText={errors.passwordConfirmation}
        />
        <Box
          position='absolute'
          right={0}
          top={0}
          bottom={0}
          mt={2}
          mb={2}
        >
          <Button
            variant='icon'
            onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
          >
            <Icon
              color='primary'
              name={confirmPasswordVisible ? 'visibility' : 'visibilityOff'}
            />
          </Button>
        </Box>
      </Box>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid item xs={12} sm={6} style={{ marginTop: '16px' }}>
          <DatePicker
            inputVariant='outlined'
            bgcolor='background.paper'
            format='MM-dd-yyyy'
            id='date-picker-inline'
            label='Birthday'
            name='birthday'
            value={selectedDate}
            keyboardIcon={<DateRangeIcon />}
            onChange={handleDateChange}
            KeyboardButtonProps={{ 'aria-label': 'change date' }}
            slotProps={{ textField: { required: true, fullWidth: true } }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            required
            label='Gender'
            name='gender'
            options={GENDER_OPTIONS}
            defaultValue=''
            helperText={errors.gender}
          />
        </Grid>
      </Grid>
      <Box mt={2}>
        <Typography variant='body2'>
          To use this account please review and agree to the following:
        </Typography>
      </Box>
      <Box display='flex' alignItems='center'>
        <Checkbox name='terms' color='primary' inputProps={{ 'aria-label': 'terms' }} />
        <Typography variant='body2' color={errors.terms ? 'error' : 'initial'}>
          <Link
            display='standard'
            size='small'
            target='_blank'
            to='/privacy-policy'
            style={{ fontWeight: '300' }}
          >
            Privacy Policy
          </Link>{' '}
          ,{' '}
          <Link
            display='standard'
            size='small'
            target='_blank'
            to='/terms'
            style={{ fontWeight: '300' }}
          >
            Terms of Use
          </Link>{' '}
          and{' '}
          <Link
            display='standard'
            size='small'
            target='_blank'
            to='/eula-consumer'
            style={{ fontWeight: '300' }}
          >
            EULA
          </Link>
          .
        </Typography>
      </Box>
      <Box display='flex' alignItems='center'>
        <Checkbox name='hipaa' color='primary' inputProps={{ 'aria-label': 'hipaa' }} />
        <Typography variant='body2' color={errors.hipaa ? 'error' : 'initial'}>
          <Link
            display='standard'
            size='small'
            target='_blank'
            to='/hipaa'
            style={{ fontWeight: '300' }}
          >
            HIPAA Authorization
          </Link>
        </Typography>
        .
      </Box>
      <Box display='flex' alignItems='center'>
        <Checkbox name='smsOptIn' color='primary' inputProps={{ 'aria-label': 'Text Opt In' }} />
        <Typography variant='body2' color='initial'>
          Agree to receieve SMS about your account and orders. Message and data rates may apply.
        </Typography>
      </Box>
      <Box mt={2}>
        <Button type='submit' fullWidth>
          Create my account
        </Button>
      </Box>
    </form >
  )
}

SignUpForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    birthday: PropTypes.string,
    email: PropTypes.string,
    firstname: PropTypes.string,
    gender: PropTypes.string,
    lastname: PropTypes.string,
    password: PropTypes.string,
    passwordConfirmation: PropTypes.string,
    terms: PropTypes.string,
    hipaa: PropTypes.string,
    phone: PropTypes.string,
  }),
}

export default SignUpForm
