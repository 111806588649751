import PropTypes from 'prop-types'
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer'
import { formatBirthdayForUI } from '../../libs/formatters'
import MisheLogo from '../../assets/images/MisheText.png'
import MeieScriptFont from '../../assets/fonts/MeieScript-Regular.ttf'

// register Meie font from the assets/fonts folder
Font.register({
  family: 'MeieScript-Regular',
  fonts: [{ src: MeieScriptFont }],
})

const styles = StyleSheet.create({
  body: {
    width: '100%',
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  sectionHeader: {
    marginVertical: 15,
  },
  sectionBody: {
    marginBottom: 10,
    marginLeft: 10,
  },
  headerText: {
    fontSize: 18,
    textTransform: 'capitalize',
  },
  headerTextCenter: {
    fontSize: 18,
    textTransform: 'capitalize',
    textAlign: 'center',
    marginTop: 15,
    marginBottom: 10,
  },
  bodyText: {
    fontSize: 14,
    textTransform: 'capitalize',
    fontWeight: 'light',
  },
  grid: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10,
  },
  gridRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  gridHeaderSmall: {
    fontSize: 16,
    fontWeight: 'bold',
    width: '14%',
    backgroundColor: '#f0f0f0',
    padding: '4px',
  },
  gridHeaderMedium: {
    fontSize: 16,
    fontWeight: 'bold',
    width: '18%',
    backgroundColor: '#f0f0f0',
    padding: '4px',
  },
  gridHeaderLarge: {
    fontSize: 16,
    fontWeight: 'bold',
    width: '50%',
    backgroundColor: '#f0f0f0',
    padding: '4px',
  },
  gridColSmall: {
    fontSize: 14,
    fontWeight: 'light',
    width: '14%',
    backgroundColor: '#f9f9f9',
    padding: '4px',
  },
  gridColMedium: {
    fontSize: 14,
    fontWeight: 'light',
    width: '18%',
    backgroundColor: '#f9f9f9',
    padding: '4px',
  },
  gridColLarge: {
    fontSize: 14,
    fontWeight: 'light',
    width: '50%',
    backgroundColor: '#f9f9f9',
    padding: '4px',
  },
  // use this MeieScript-Regular from the assets/fonts folder
  signatureFont: {
    fontSize: 20,
    textTransform: 'capitalize',
    borderBottom: '1px solid #000',
    marginRight: 100,
    fontFamily: 'MeieScript-Regular',
  },
  misheLogoContainer: {
    width: '100%',
    marginBottom: 30,
  },
  misheLogo: {
    marginLeft: 'auto',
    width: 100,
  },
  clinicLogo: {
    width: 50,
  },
  sectionFlexContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  sectionFlexItemSmall: {
    width: '20%',
  },
  sectionFlexItemLarge: {
    width: '80%',
  },
  displayBottom: {
    marginTop: 'auto',
  },
})

const PDFTemplate = ({ order, clinicLogo = null }) => {
  console.log('PDFTemplate order:', order) // eslint-disable-line

  const {
    details = {},
    patient = {},
    referring = {},
    referringClinic = {},
    referringProvider = {},
  } = order

  const { clinic = {}, provider = {} } = referring
  const { diagnosisCode, serviceData = {} } = details

  if (details?.medicationName) {
    serviceData.name = `${details?.count || ''} ${
      details?.medicationName || ''
    } ${details?.medicationStrength || ''} ${details?.strength || ''}`
  }

  const formattedClinicData = {
    clinicName: clinic?.name || referringClinic?.name || null,
    providerName: provider?.name || referringProvider?.name || null,
    email:
      clinic?.email ||
      referringClinic?.email ||
      provider?.email ||
      referringProvider?.email ||
      null,
    phone:
      clinic?.phone ||
      referringClinic?.phone ||
      provider?.phone ||
      referringProvider?.phone ||
      null,
    npi: clinic?.npi || referringClinic?.npi || provider?.npi || referringProvider?.npi || null,
  }

  return (
    <Document>
      <Page size='A4' style={styles.body}>
        <View style={styles.misheLogoContainer}>
          <Image src={MisheLogo} style={styles.misheLogo} />
        </View>
        {clinicLogo && <Image src={clinicLogo} style={styles.clinicLogo} />}
        <View style={styles.sectionHeader}>
          <Text style={styles.headerText}>Clinic Information</Text>
        </View>
        <View style={styles.sectionBody}>
          <View style={styles.sectionFlexContainer}>
            <View style={styles.sectionFlexItemSmall}>
              {formattedClinicData?.providerName && <Text style={styles.bodyText}>Physician:</Text>}
              {formattedClinicData?.clinicName && <Text style={styles.bodyText}>Clinic:</Text>}
              {formattedClinicData?.npi && <Text style={styles.bodyText}>NPI:</Text>}
              {formattedClinicData?.email && <Text style={styles.bodyText}>Email:</Text>}
              {formattedClinicData?.phone && <Text style={styles.bodyText}>Phone:</Text>}
            </View>
            <View style={styles.sectionFlexItemLarge}>
              {formattedClinicData?.clinicName && (
                <Text style={styles.bodyText}>{formattedClinicData?.providerName || ''}</Text>
              )}
              {formattedClinicData?.providerName && (
                <Text style={styles.bodyText}>{formattedClinicData?.clinicName || ''}</Text>
              )}
              {formattedClinicData?.npi && (
                <Text style={styles.bodyText}>{formattedClinicData?.npi || ''}</Text>
              )}
              {formattedClinicData?.email && (
                <Text style={styles.bodyText}>{formattedClinicData?.email || ''}</Text>
              )}
              {formattedClinicData?.phone && (
                <Text style={styles.bodyText}>{formattedClinicData?.phone || ''}</Text>
              )}
            </View>
          </View>
        </View>
        <View style={styles.sectionHeader}>
          <Text style={styles.headerText}>Patient Information</Text>
        </View>
        <View style={styles.sectionBody}>
          <View style={styles.sectionFlexContainer}>
            <View style={styles.sectionFlexItemSmall}>
              {patient?.lastname && <Text style={styles.bodyText}>Name:</Text>}
              {patient?.phone && <Text style={styles.bodyText}>Phone:</Text>}
              {patient?.dob && <Text style={styles.bodyText}>DOB:</Text>}
              {patient?.email && <Text style={styles.bodyText}>Email:</Text>}
              {patient?.gender && <Text style={styles.bodyText}>Gender:</Text>}
            </View>
            <View style={styles.sectionFlexItemLarge}>
              {patient?.lastname && (
                <Text style={styles.bodyText}>
                  {patient?.firstname || ''} {patient?.lastname || ''}
                </Text>
              )}
              {patient?.phone && <Text style={styles.bodyText}>{patient?.phone || ''}</Text>}
              {patient?.dob && (
                <Text style={styles.bodyText}>{formatBirthdayForUI(patient?.dob) || ''}</Text>
              )}
              {patient?.email && <Text style={styles.bodyText}>{patient?.email || ''}</Text>}
              {patient?.gender && <Text style={styles.bodyText}>{patient?.gender || ''}</Text>}
            </View>
          </View>
        </View>
        <View style={styles.sectionHeader}>
          <Text style={styles.headerTextCenter}>PHYSICIAN ORDER FORM</Text>
        </View>
        <View style={styles.grid}>
          <View style={styles.gridRow}>
            <Text style={styles.gridHeaderSmall}>Date</Text>
            <Text style={styles.gridHeaderLarge}>Orders</Text>
            <Text style={styles.gridHeaderMedium}>CPT Code</Text>
            <Text style={styles.gridHeaderMedium}>Diagnoses</Text>
          </View>
          {/* CPT row generator */}

          <View style={styles.gridRow}>
            {order?.createdAt && (
              <Text style={styles.gridColSmall}>
                {order?.createdAt?.toDate()?.toLocaleDateString() || ''}
              </Text>
            )}
            {serviceData?.name && (
              <Text style={styles.gridColLarge}>{serviceData?.name || ''}</Text>
            )}
            {serviceData?.cptCode && (
              <Text style={styles.gridColMedium}>{serviceData?.cptCode || ''}</Text>
            )}
            {diagnosisCode && <Text style={styles.gridColMedium}>{diagnosisCode || ''}</Text>}
          </View>
        </View>
        {/* End Subtotal and total */}
        {/* Provider signature */}
        <View style={styles.displayBottom}>
          <View style={styles.sectionFlexContainer}>
            <View style={styles.sectionFlexItemLarge}>
              <View style={styles.sectionHeader}>
                <Text style={styles.headerText}>Provider Signature</Text>
              </View>
              <Text style={styles.signatureFont}>{formattedClinicData?.providerName || ''}</Text>
            </View>
            <View style={styles.sectionFlexItemSmall}>
              <View style={styles.sectionHeader}>
                <Text style={styles.headerText}>Date</Text>
              </View>
              <Text style={styles.bodyText}>{new Date().toLocaleDateString()}</Text>
            </View>
          </View>
        </View>
        {/* End provider signature */}
      </Page>
    </Document>
  )
}

PDFTemplate.propTypes = {
  order: PropTypes.object.isRequired,
  clinicLogo: PropTypes.string,
}

export default PDFTemplate
