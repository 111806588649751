import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Container from '../stories/Container'
import Section from '../stories/Section'
import Box from '@mui/material/Box'

import InstantSearchContainerCustom from '../containers/InstantSearchContainerCustom'
import ServiceSearchCardNetwork from './ServiceSearchCardNetwork'
import ProviderSearchCard from './ProviderSearchCard'

const NetworkCareTypesListNetwork = ({ networkSlug, networkData }) => {
  const { searchTypes } = networkData

  const ProvidersSearchComponent = () => {
    return (
      <InstantSearchContainerCustom
        HitComponent={ProviderSearchCard}
        // hitClick={`/plans/${networkSlug}/service/`}
        indexName='providers'
        showInitialHits
        configuration={{
          hitsPerPage: 5,
        }}
        searchBoxText='Search for a provider'
        noResultsMessage='No results for'
        customZIndex={5}
      />
    )
  }

  const SearchComponent = () => {
    return (
      <Box
        width='100%'
        sx={{
          minHeight: {
            xs: '110vh',
            sm: '110vh',
            md: '75vh',
            lg: '75vh',
          },
        }}
      >
        <InstantSearchContainerCustom
          HitComponent={ServiceSearchCardNetwork}
          hitClick={`/plans/${networkSlug}/service/`}
          indexName='network_services'
          showInitialHits
          configuration={{
            filters: `network.slug:${networkSlug}`,
            hitsPerPage: 5,
          }}
          searchBoxText='Search for symptom, service, or provider'
          noResultsMessage='No results for'
          noResultsColor='#000'
          customZIndex={5}
          networkLanderSearch
        />
      </Box>
    )
  }

  if (!searchTypes || searchTypes?.length === 0) {
    return (
      <Section id='specialties'>
        <Container>
          <Box position='relative'>
            <SearchComponent />
          </Box>
        </Container>
      </Section>
    )
  }

  if (searchTypes.includes('providers')) {
    return (
      <Section id='specialties'>
        <Container>
          <Grid container spacing={3} display='flex' alignItems='center'>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ height: { xs: '90vh', md: '60vh' } }}>
              <ProvidersSearchComponent />
            </Grid>
          </Grid>
        </Container>
      </Section>
    )
  }

  return (
    <Section id='specialties'>
      <Container>
        <Grid container spacing={3} display='flex' alignItems='center'>
          <Grid item xs={12} sm={12} md={12} lg={12} style={{ height: '80vh' }}>
            <SearchComponent />
          </Grid>
        </Grid>
      </Container>
    </Section>
  )
}

NetworkCareTypesListNetwork.propTypes = {
  networkSlug: PropTypes.string.isRequired,
}

export default NetworkCareTypesListNetwork
