import { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'

import misheLogoWhite from '../assets/images/LogoTextWhite.svg'

const WhiteLabeling = createContext()

export const useWhiteLabelingContext = () => useContext(WhiteLabeling)

export const WhiteLabelingProvider = ({ children }) => {
  const defaultLogo = misheLogoWhite

  // Accepts URL or Data URI
  const [logoSource, setLogoSource] = useState(defaultLogo)
  // Array of jsx elements
  const [customNavElements, setCustomNavElements] = useState([])
  // show/hide default nav elements
  const [showDefaultNavElements, setShowDefaultNavElements] = useState(true)
  // top bar color
  const [planColorPrimary, setPlanColorPrimary] = useState('#652d92')
  const [planColorSecondary, setPlanColorSecondary] = useState('#fff')

  const value = {
    setLogoSource,
    logoSource,
    setCustomNavElements,
    customNavElements,
    setShowDefaultNavElements,
    showDefaultNavElements,
    setPlanColorPrimary,
    planColorPrimary,
    setPlanColorSecondary,
    planColorSecondary,
  }
  return <WhiteLabeling.Provider value={value}>{children}</WhiteLabeling.Provider>
}

WhiteLabelingProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default WhiteLabelingProvider
