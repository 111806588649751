import { useCallback, useState } from 'react'

const DEFAULT_RESULT = {
  hits: null,
  facets: null,
  filters: null,
  hasMore: null,
  currentPage: -1,
}

const useSearchResult = () => {
  const [result, setResult] = useState(DEFAULT_RESULT)

  const changeResult = useCallback(
    (response, saveFacets = false, saveFilters = false) => {
      const { hits = [], facets = null, filters = null, page = 0, nbPages = 0 } = response
      setResult(previous => {
        const newResult = {
          ...previous,
          // find if there are more pages with hits
          hasMore: page < nbPages - 1,
          // if not first page, concat new hits with previous hits
          hits: page > 0 ? previous.hits.concat(hits) : hits,
          currentPage: page,
        }

        if (saveFacets) {
          newResult.facets = facets
        }
        if (saveFilters) {
          newResult.filters = filters
        }
        return newResult
      })
    },
    [setResult],
  )

  return [result, changeResult]
}

export default useSearchResult
