import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Hidden from '@mui/material/Hidden'
import Container from '../stories/Container'
import Section from '../stories/Section'
import Typography from '../stories/Typography'
import Avatar from '../stories/Avatar'
import IconButton from '../stories/IconButton'
import Icon from '../stories/Icons'

import MisheCoFounder from '../assets/images/co-founder.png'
import MisheCEO from '../assets/images/ceo-mishe.png'

const About = () => {
  const { search } = useLocation()
  const ref = new URLSearchParams(search).get('ref')

  if (ref) {
    localStorage.setItem('refvalue', ref)
  }

  useEffect(() => {
    window.Intercom('trackEvent', 'Page View', {
      page_name: 'AboutPage',
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Helmet>
        <title>About Mishe We&apos;re just like you. We deserve a better system.</title>
        <meta name='title' content="About Mishe We're just like you. We deserve a better system." />
        <meta
          name='description'
          content='We envision a world where everyone can access affordable care - where you can pursue your passions fearlessly without worrying about available treatment options.'
        />

        <meta property='og:type' content='website' />
        <meta property='og:url' content='https://mishe.co/about' />
        <meta
          property='og:title'
          content="About Mishe We're just like you. We deserve a better system."
        />
        <meta
          property='og:description'
          content='We envision a world where everyone can access affordable care - where you can pursue your passions fearlessly without worrying about available treatment options.'
        />
        <meta property='og:image' content='/og-image.png' />

        <meta property='twitter:card' content='summary_large_image' />
        <meta property='twitter:url' content='https://mishe.co/about' />
        <meta
          property='twitter:title'
          content="About Mishe We're just like you. We deserve a better system."
        />
        <meta
          property='twitter:description'
          content='We envision a world where everyone can access affordable care - where you can pursue your passions fearlessly without worrying about available treatment options.'
        />
        <meta property='twitter:image' content='/og-image.png' />
      </Helmet>
      <Section variant='about'>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Typography variant='caption' weight='semibold'>
                <Box component='span' color='primary.main'>
                  a message from the founders
                </Box>
              </Typography>
              <Typography variant='h1' gutterBottom>
                This is{' '}
                <Box component='span' color='primary.main'>
                  why{' '}
                </Box>
                we created mishe.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Typography variant='body1' weight='light' style={{ maxWidth: '548px' }}>
                We’re just like you. We know what it’s like to be caught off guard by a surprise
                bill, or turned away because your insurance isn’t accepted. We’ve paid out of pocket
                for services we thought were covered by insurance, only to find out that the cash
                price was cheaper. We’ve thought twice about getting treatment without knowing
                exactly what it would cost. We’ve gone years without coming close to reaching our
                deductibles. We’ve spent time without any insurance coverage at all. We’ve heard
                horror stories from friends, family, and the community about how the healthcare
                system left them in financial pain despite bringing physical relief. We know hurt
                and we share the desire to heal.
                <br /> <br />
                We both pursued careers in healthcare early in life because we wanted to help
                people. After time, our experiences on opposite sides of the health system left us
                feeling jaded and hopeless. We were frustrated with the increasing costs for
                decreasing coverage, and grew tired of making excuses for delays and denials of care
                and reimbursement. We longed for an alternative that helped people without causing
                additional pain. So, we created it. <br /> <br />
                We envision a world where everyone can access affordable care. We want you to be
                able to pursue your passions fearlessly without worrying about treatment options. We
                want you to be able to access care without fearing unknown costs. We want you to be
                able to budget for care, and build wellness and preventative maintenance into your
                lifestyle and financial plans. We want to help improve the relationships you have
                with your care providers, and with your overall health. We want to help put control
                and power back in your hands. <br /> <br />
                To achieve this, we built a model that brings value to all, regardless of insurance
                coverage. We built a model that helps the ones we love, and those we have yet to
                meet. We built a model that helps us take back healthcare; a model that helps us
                heal.
              </Typography>
            </Grid>
            <Grid item xs={12} md={1} lg={1} />
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Box display='flex' flexDirection='column' alignItems='center'>
                    <Avatar src={MisheCEO} alt='Sidney Haitoff' size='mediumlarge'>
                      Sidney Haitoff
                    </Avatar>
                  </Box>
                </Grid>
                <Grid item xs={12} md={1} lg={1} />
                <Grid item xs={12} sm={6} md={7} lg={7}>
                  <Box display='flex' alignItems='center' height='100%'>
                    <Hidden mdDown>
                      <Box>
                        <Typography variant='h6' component='h2' weight='semibold'>
                          Sidney Haitoff
                          <IconButton
                            target='_blank'
                            href='https://www.linkedin.com/in/sidneyhaitoff/'
                            size='large'
                          >
                            <Icon name='linkedin' />
                          </IconButton>
                        </Typography>
                        <Typography variant='body2' weight='light' style={{ fontStyle: 'italic' }}>
                          Co-Founder & Chief Executive Officer
                        </Typography>
                      </Box>
                    </Hidden>
                    <Hidden mdUp>
                      <Box margin='auto'>
                        <Typography variant='h6' component='h2' align='center' weight='semibold'>
                          Sidney Haitoff
                          <IconButton
                            target='_blank'
                            href='https://www.linkedin.com/in/sidneyhaitoff/'
                            size='large'
                          >
                            <Icon name='linkedin' />
                          </IconButton>
                        </Typography>
                        <Typography
                          variant='body2'
                          align='center'
                          weight='light'
                          style={{ fontStyle: 'italic' }}
                        >
                          Co-Founder & Chief Executive Officer
                        </Typography>
                      </Box>
                    </Hidden>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ paddingTop: '30px' }}>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Box display='flex' flexDirection='column' alignItems='center'>
                    <Avatar src={MisheCoFounder} alt='Dr. Sandeep Palakodeti' size='mediumlarge'>
                      Dr. Sandeep Palakodeti
                    </Avatar>
                  </Box>
                </Grid>
                <Grid item xs={12} md={1} lg={1} />
                <Grid item xs={12} sm={6} md={7} lg={7}>
                  <Box display='flex' alignItems='center' height='100%'>
                    <Hidden mdDown>
                      <Box>
                        <Typography variant='h6' component='h2' weight='semibold'>
                          Sandeep Palakodeti, MD, MPH
                          <IconButton
                            target='_blank'
                            href='https://www.linkedin.com/in/sandeep-palakodeti-md-mph-1b03436a/'
                            size='large'
                          >
                            <Icon name='linkedin' />
                          </IconButton>
                        </Typography>
                        <Typography variant='body2' weight='light' style={{ fontStyle: 'italic' }}>
                          Co-Founder & Chief Product Officer
                        </Typography>
                      </Box>
                    </Hidden>
                    <Hidden mdUp>
                      <Box margin='auto'>
                        <Typography variant='h6' component='h2' align='center' weight='semibold'>
                          Sandeep Palakodeti, MD, MPH
                          <IconButton
                            target='_blank'
                            href='https://www.linkedin.com/in/sandeep-palakodeti-md-mph-1b03436a/'
                            size='large'
                          >
                            <Icon name='linkedin' />
                          </IconButton>
                        </Typography>
                        <Typography
                          variant='body2'
                          align='center'
                          weight='light'
                          style={{ fontStyle: 'italic' }}
                        >
                          Co-Founder & Chief Product Officer
                        </Typography>
                      </Box>
                    </Hidden>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </div>
  )
}

export default About
