import firebase from './app'
import 'firebase/compat/storage'

const storage = () => firebase.storage()

export const uploadFile = async (path, file, metadata = {}) => {
  try {
    console.log('  -- Uploading file')
    console.log('  -- Path: ', path)
    console.log('  -- File: ', file)
    console.log('  -- Metadata: ', metadata)
    const storageRef = storage().ref()
    const fileRef = storageRef.child(path)
    await fileRef.put(file, metadata)
    return fileRef.getDownloadURL()
  } catch (error) {
    console.log('**** File Upload Error ****') // eslint-disable-line
    console.log(error) // eslint-disable-line
    return null
  }
}

// export const downloadFile = async (path, file) => {
//   try {
//     const storageRef = storage().ref()
//     const fileRef = storageRef.child(path)
//   } catch (error) {
//     console.log('**** File Download Error ****') // eslint-disable-line
//     console.log(error) // eslint-disable-line
//     return null
//   }
// }

export const getFileUrl = path => {
  const storageRef = storage().ref()
  const fileRef = storageRef.child(path)
  return fileRef.getDownloadURL()
}

export default storage
