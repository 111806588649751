import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { Divider, Hidden } from '@mui/material'
import Button from '../stories/Button'
import Container from '../stories/Container'
import Section from '../stories/Section'
import Typography from '../stories/Typography'
import MisheNetworkHeroLong from '../assets/images/Mishe-Network-Hero-Long.png'
import MisheLeaves from '../assets/images/MisheLeaves.png'
import FormattedText from '../stories/FormatFromDB'

import useSignInUpModals from '../hooks/useSignInUpModals'
import SignUpModal from './SignUpModal'
import SignInModal from './SignInModal'

const NetworkHeroLogin = ({ reason = null, loginMethods }) => {
  const { signUpOpen, signInOpen, handleOpen } = useSignInUpModals()

  const headerText = reason === 'not-loggedIn' ? 'Sign in' : 'Become a member'

  return (
    <Section variant='noPadding'>
      <Container style={{ position: 'relative' }}>
        <SignUpModal open={signUpOpen} handleClose={() => handleOpen('signUp')} />
        <SignInModal
          open={signInOpen}
          loginMethods={loginMethods}
          preventRegister={loginMethods?.length === 0 || !loginMethods?.includes('register')}
          handleClose={() => handleOpen('signIn')}
        />
        <Grid container spacing={3} display='flex' alignItems='center'>
          <Hidden mdDown>
            <>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box
                  maxWidth={520}
                  style={{
                    position: 'absolute',
                    left: '80px',
                  }}
                >
                  <Box
                    style={{
                      boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.3)',
                      borderRadius: '5px',
                      backgroundColor: 'white',
                      padding: '25px',
                    }}
                  >
                    <Box>
                      <Typography
                        variant='h3'
                        color='primary'
                        family='poppins'
                        style={{
                          marginBottom: '3px',
                        }}
                      >
                        {`${headerText} for a personalized`}
                      </Typography>
                      <Typography family='poppins' variant='h3' color='primary'>
                        view of your benefits
                      </Typography>
                      <Typography
                        variant='body1'
                        weight='medium'
                        style={{
                          marginBottom: '0px',
                        }}
                      >
                        Easy access to plan information anytime anywhere.
                      </Typography>
                      <Typography
                        variant='body1'
                        weight='medium'
                        style={{
                          marginBottom: '20px',
                        }}
                      >
                        Get the most out of your coverage.
                      </Typography>
                    </Box>
                    {reason === 'not-loggedIn' && (
                      <Box mt={3}>
                        <Box mb={3}>
                          <Divider vertical />
                        </Box>
                        <Grid
                          container
                          display='flex'
                          alignItems='center'
                          justifyContent='space-around'
                        >
                          <Button
                            size='medium'
                            family='poppins'
                            onClick={() => handleOpen('signIn')}
                            style={{
                              width: '40%',
                            }}
                          >
                            Sign In
                          </Button>
                          {(loginMethods?.length === 0 || loginMethods?.includes('register')) && (
                            <Button
                              size='medium'
                              variant='outlined'
                              family='poppins'
                              onClick={() => handleOpen('signUp')}
                              style={{
                                width: '40%',
                              }}
                            >
                              Register
                            </Button>
                          )}
                        </Grid>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <img src={MisheNetworkHeroLong} alt='Network Hero' width='100%' />
              </Grid>
            </>
          </Hidden>
          <Hidden mdUp>
            <>
              <Grid item xs={12} sm={12} style={{ margin: '46px 0px 0px 0px' }}>
                <Typography
                  variant='h1'
                  gutterBottom
                  align='center'
                  weight='medium'
                  style={{
                    fontFamily: 'Poppins',
                  }}
                >
                  Healthcare is a message away
                </Typography>
                <FormattedText
                  text='Get treatment for common health concerns at your convenience - no appointments or video calls required'
                  textType='body1'
                  weight='light'
                  align='center'
                  style={{
                    fontFamily: 'Poppins',
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Section
                  style={{
                    position: 'relative',
                    padding: '26px 0px 60px',
                  }}
                >
                  <Box
                    style={{
                      position: 'absolute',
                      top: '0px',
                      left: '-50px',
                      zIndex: '-1',
                      width: '120%',
                      height: '100%',
                      backgroundImage: `url(${MisheLeaves})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      backgroundPosition: 'bottom',
                    }}
                  />
                  <Box>
                    <Box
                      style={{
                        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.3)',
                        borderRadius: '5px',
                        backgroundColor: 'rgba(255,255,255,0.8)',
                        padding: '25px',
                      }}
                    >
                      <Box>
                        <Typography
                          variant='h3'
                          color='primary'
                          style={{
                            marginBottom: '3px',
                            fontSize: '24px',
                          }}
                        >
                          {`${headerText} for a personalized`}
                        </Typography>
                        <Typography
                          family='poppins'
                          variant='h3'
                          color='primary'
                          style={{
                            fontSize: '24px',
                          }}
                        >
                          view of your benefits
                        </Typography>
                        <Typography
                          variant='body1'
                          weight='light'
                          style={{
                            marginBottom: '0px',
                            fontSize: '16px',
                          }}
                        >
                          Get the most out of your coverage.
                        </Typography>
                        <Typography
                          variant='body1'
                          weight='light'
                          style={{
                            fontSize: '16px',
                          }}
                        >
                          Easy access to plan information anytime anywhere.
                        </Typography>
                      </Box>
                      {reason === 'not-loggedIn' && (
                        <Box mt={3}>
                          <Box mb={3}>
                            <Divider vertical />
                          </Box>
                          <Grid
                            container
                            display='flex'
                            alignItems='center'
                            justifyContent='space-around'
                          >
                            <Button
                              size='medium'
                              family='poppins'
                              onClick={() => handleOpen('signIn')}
                              style={{
                                width: '40%',
                              }}
                            >
                              Sign In
                            </Button>
                            {(loginMethods?.length === 0 || loginMethods?.includes('register')) && (
                              <Button
                                size='medium'
                                variant='outlined'
                                family='poppins'
                                onClick={() => handleOpen('signUp')}
                                style={{
                                  width: '40%',
                                }}
                              >
                                Register
                              </Button>
                            )}
                          </Grid>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Section>
              </Grid>
            </>
          </Hidden>
        </Grid>
      </Container>
    </Section>
  )
}

NetworkHeroLogin.propTypes = {
  reason: PropTypes.string,
}

export default NetworkHeroLogin
