import { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import { Divider, Hidden } from '@mui/material'
import TextField from '../stories/TextField'
import Button from '../stories/Button'
import Typography from '../stories/Typography'
import Icon from '../stories/Icons'

import useChat from '../hooks/useChat'
import MessageIn from './Chats/MessageIn'
import MessageOut from './Chats/MessageOut'

import { useCurrentProfile, useCurrentUser } from '../context/CurrentUser'

const VoucherChat = ({ chatID }) => {
  const [displayLinks, setDisplayLinks] = useState(false)
  const [fullScreen, setFullScreen] = useState(null)

  const [messages, setMessages] = useState(null)

  const [currentImage, setCurrentImage] = useState(null)
  const [currentFile, setCurrentFile] = useState(null)
  const [messageType, setMessageType] = useState(null)

  const desktopFileRef = useRef()
  const mobileFileRef = useRef()

  const desktopImageRef = useRef()
  const mobileImageRef = useRef()

  const profile = useCurrentProfile()
  const { uid } = useCurrentUser()
  const { subscribeToChat, sendMessage } = useChat()

  const handleImageChange = e => {
    setCurrentFile(null) // was 0
    setCurrentImage(null)
    setCurrentImage({
      file: e.target.files[0],
      url: URL.createObjectURL(e.target.files[0]),
    })
  }

  const MessageForm = ({ children }) => {
    return (
      <form
        onSubmit={e => {
          e.preventDefault()
          setDisplayLinks(displayLinks && !displayLinks)
          const chatMessageBoxValue = document.getElementById('chatMessageBox').value
          sendMessage({
            chatID,
            message: chatMessageBoxValue,
            profile,
            currentFile,
            currentImage,
            fileType: messageType,
          })
          document.getElementById('chatMessageBox').value = ''
          setCurrentFile(null)
          setCurrentImage(null)
          setMessageType(null)
        }}
        style={{
          width: '100%',
        }}
      >
        {children}
      </form>
    )
  }

  MessageForm.propTypes = {
    children: PropTypes.node.isRequired,
  }

  useEffect(() => {
    if (!chatID) {
      return undefined
    }
    console.log('chatID', chatID)

    const unsubscribe = subscribeToChat({
      chatID,
      callback: setMessages,
    })

    return unsubscribe
  }, [chatID]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (window?.Intercom) {
      window.Intercom('update', { hide_default_launcher: fullScreen || false })
    }
  }, [fullScreen]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Hidden mdDown>
        <Box
          mt={2}
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            flexDirection: 'column',
            maxHeight: '80vh',
            minHeight: '50vh',
            border: '1px solid rgba(0, 0, 0, 0.12)',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
            borderRadius: '4px',
          }}
        >
          <Box
            style={{
              position: 'relative',
              width: '100%',
              maxHeight: '80vh',
              minHeight: '50vh',
              display: 'flex',
              flexDirection: 'column-reverse',
              alignItems: 'flex-end',
              overflow: 'scroll',
              borderRadius: '4px',
              boxShadow: 'inset 0 16px 8px -16px rgba(25,25,25,0.25)',
              padding: '4px 10px',
            }}
          >
            {messages &&
              messages.map((message, index) => {
                if (message.sentById === uid) {
                  return (
                    <MessageOut
                      key={index} // eslint-disable-line react/no-array-index-key
                      author={message?.sentByName}
                      content={message?.content}
                      sendTime={message?.sentAt}
                      date={message?.date}
                    />
                  )
                }
                return (
                  <MessageIn
                    key={index} // eslint-disable-line react/no-array-index-key
                    author={message?.sentByName}
                    content={message?.content}
                    sendTime={message?.sentAt}
                    date={message?.date}
                  />
                )
              })}
          </Box>
          <Box
            style={{
              width: '100%',
              display: 'flex',
              position: 'relative',
              justifyContent: 'space-around',
              alignItems: 'flex-end',
              marginTop: '0px',
              paddingTop: '10px',
              borderTop: 'solid #eee 1px',
              padding: '10px',
            }}
          >
            <Box
              style={{
                position: 'absolute',
                zIndex: '2',
                left: '10px',
                bottom: '10px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                variant='outlined'
                size='small'
                type='button'
                onClick={() => {
                  setDisplayLinks(!displayLinks)
                }}
                style={{
                  borderRadius: '50%',
                  minHeight: '40px',
                  minWidth: '40px',
                  maxWidth: '40px',
                  boxShadow: '0px 0px 15px 3px rgba(0, 0, 0, 0.25)',
                }}
              >
                {!displayLinks && <Icon name='add' color='primary' />}
                {displayLinks && <Icon name='openArrow' color='primary' />}
              </Button>
              {displayLinks && (
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    position: 'absolute',
                    left: '0px',
                    bottom: '60px',
                    maxWidth: '300px',
                    minWidth: 'fit-content',
                  }}
                >
                  {/* <Box>
                  <Button
                    variant='outlined'
                    size='small'
                    onClick={() => {
                      setDisplayLinks(!displayLinks)
                      window.location.href = `/videochat?id=${chatID}`
                    }}
                    style={{
                      borderRadius: '50%',
                      minHeight: '40px',
                      minWidth: '40px',
                      maxWidth: '40px',
                      boxShadow: '0px 0px 15px 3px rgba(0, 0, 0, 0.25)'
                    }}
                  >
                    <Icon name='videoCall' color='white' />
                  </Button>
                </Box> */}
                  <Box
                    style={{
                      position: 'relative',
                    }}
                  >
                    <Button
                      variant='outlined'
                      size='small'
                      onClick={() => {
                        desktopImageRef.current.click()
                      }}
                      style={{
                        marginTop: '8px',
                        borderRadius: '50%',
                        minHeight: '40px',
                        minWidth: '40px',
                        maxWidth: '40px',
                        boxShadow: '0px 0px 15px 3px rgba(0, 0, 0, 0.25)',
                      }}
                    >
                      <input
                        hidden
                        type='file'
                        ref={desktopImageRef}
                        name='chatImageInput'
                        accept='image/png, image/gif, image/jpeg'
                        onChange={e => {
                          setMessageType('image')
                          handleImageChange(e)
                        }}
                      />
                      <Icon name='addPhoto' color='white' />
                    </Button>
                    {currentImage && (
                      <Box
                        style={{
                          position: 'absolute',
                          bottom: '-5px',
                          left: '50px',
                        }}
                      >
                        <img
                          src={currentImage.url}
                          alt='preview'
                          style={{
                            border: 'solid 2px #652d92',
                            borderRadius: '4px',
                            objectFit: 'contain',
                            maxWidth: '200px',
                            maxHeight: '200px',
                            boxShadow: '0px 0px 0px 1px rgba(255, 255, 255, 0.5)',
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                  <Box
                    style={{
                      marginTop: '8px',
                      maxHeight: '40px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#fff',
                      boxShadow: 'inset 0px 0px 0px 1px rgba(101, 45, 146, 0.5)',
                      borderRadius: '20px',
                    }}
                  >
                    <Button
                      variant='outlined'
                      size='small'
                      onClick={() => {
                        desktopFileRef.current.click()
                      }}
                      style={{
                        borderRadius: '50%',
                        minHeight: '40px',
                        minWidth: '40px',
                        maxWidth: '40px',
                        boxShadow: '0px 0px 15px 3px rgba(0, 0, 0, 0.25)',
                      }}
                    >
                      <input
                        hidden
                        type='file'
                        ref={desktopFileRef}
                        onChange={e => {
                          setCurrentImage(null)
                          setMessageType('file')
                          setCurrentFile(e.target.files[0])
                        }}
                      />
                      <Icon name='attachFile' color='white' />
                    </Button>
                    {currentFile && (
                      <Box
                        style={{
                          whiteSpace: 'nowrap',
                          maxWidth: '250px',
                          padding: '0px 10px',
                        }}
                      >
                        <Typography
                          variant='body1'
                          color='primary'
                          family='poppins'
                          style={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            width: '100%',
                            maxHeight: '24px',
                          }}
                        >
                          {currentFile.name}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
            <MessageForm>
              <Box
                style={{
                  width: '100%',
                }}
              >
                <TextField
                  id='chatMessageBox'
                  name='message'
                  size='small'
                  margin='none'
                  placeholder='Message...'
                  fullWidth
                  multiline
                  style={{
                    padding: '0px 50px',
                  }}
                />
              </Box>
              <Button
                size='small'
                type='submit'
                style={{
                  color: '#fff',
                  position: 'absolute',
                  right: '10px',
                  bottom: '10px',
                  borderRadius: '50%',
                  minHeight: '40px',
                  minWidth: '40px',
                  maxWidth: '40px',
                  marginLeft: '16px',
                  boxShadow: '0px 0px 15px 3px rgba(0, 0, 0, 0.25)',
                }}
              >
                <Icon name='send' color='inherit' />
              </Button>
            </MessageForm>
          </Box>
        </Box>
      </Hidden>
      <Hidden mdUp>
        {fullScreen && (
          <Box
            style={{
              position: 'fixed',
              zIndex: '10000',
              width: '100vw',
              height: '100vh',
              backgroundColor: 'white',
              top: '0px',
              left: '0px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <Box
              style={{
                position: 'relative',
                zIndex: '10001',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100vw',
                height: '64px',
                padding: '3vw',
                marginBottom: '0px',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                backgroundColor: '#fff',
              }}
            >
              <Typography
                variant='h3'
                family='poppins'
                style={{
                  margin: '0px',
                }}
              >
                Mishe Clinic
              </Typography>
              <Button
                onClick={() => {
                  setFullScreen(null)
                  document.body.style.overflow = 'auto'
                }}
                variant='outlined'
                size='small'
                style={{
                  zIndex: '10001',
                  minHeight: '32px',
                  minWidth: '32px',
                  maxWidth: '32px',
                  borderRadius: '50%',
                  border: 'none',
                  boxShadow: 'none',
                  transform: 'rotate(180deg)',
                }}
              >
                <Icon name='exitToApp' color='white' size='large' />
              </Button>
            </Box>
            <Box
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'flex-start',
                flexDirection: 'column-reverse',
                margin: '0px',
                height: 'calc(100vh - 128px)',
                maxHeight: '100vh',
                width: '100vw',
                padding: '0px 10px',
                overflowY: 'scroll',
              }}
            >
              {messages &&
                messages.map((message, index) => {
                  if (message.sentById === uid) {
                    return (
                      <MessageOut
                        key={index} // eslint-disable-line react/no-array-index-key
                        author={message?.sentByName}
                        content={message?.content}
                        sendTime={message?.sentAt}
                        date={message?.date}
                      />
                    )
                  }
                  return (
                    <MessageIn
                      key={index} // eslint-disable-line react/no-array-index-key
                      author={message?.sentByName}
                      content={message?.content}
                      sendTime={message?.sentAt}
                      date={message?.date}
                    />
                  )
                })}
            </Box>
            <Box
              style={{
                position: 'relative',
                height: '64px',
                zIndex: '10001',
                width: '100vw',
                padding: '10px',
                borderTop: 'solid #eee 1px',
              }}
            >
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'flex-end',
                }}
              >
                <Box
                  style={{
                    position: 'relative',
                    width: '100vw',
                    backgroundColor: '#fff',
                  }}
                >
                  <Box
                    style={{
                      position: 'absolute',
                      zIndex: '20000',
                      left: '8px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      variant='outlined'
                      size='small'
                      type='button'
                      onClick={() => {
                        setDisplayLinks(!displayLinks)
                      }}
                      style={{
                        borderRadius: '50%',
                        minHeight: '40px',
                        minWidth: '40px',
                        maxWidth: '40px',
                      }}
                    >
                      {!displayLinks && <Icon name='add' color='primary' />}
                      {displayLinks && <Icon name='openArrow' color='primary' />}
                    </Button>
                    {displayLinks && (
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          justifyContent: 'center',
                          position: 'fixed',
                          left: '8px',
                          bottom: '72px',
                        }}
                      >
                        {/* <Box>
                        <Button
                          variant='outlined'
                          size='small'
                          onClick={() => {
                            setDisplayLinks(!displayLinks)
                            window.location.href = `/videochat?id=${chatID}`
                          }}
                          style={{
                            borderRadius: '50%',
                            minHeight: '40px',
                            minWidth: '40px',
                            maxWidth: '40px',
                            boxShadow: '0px 0px 15px 3px rgba(0, 0, 0, 0.25)'
                          }}
                        >
                          <Icon name='videoCall' color='white' />
                        </Button>
                      </Box> */}
                        <Box
                          style={{
                            position: 'relative',
                          }}
                        >
                          <Button
                            variant='outlined'
                            size='small'
                            onClick={() => {
                              mobileImageRef.current.click()
                            }}
                            style={{
                              marginTop: '8px',
                              borderRadius: '50%',
                              minHeight: '40px',
                              minWidth: '40px',
                              maxWidth: '40px',
                              boxShadow: '0px 0px 15px 3px rgba(0, 0, 0, 0.25)',
                            }}
                          >
                            <input
                              hidden
                              type='file'
                              ref={mobileImageRef}
                              name='chatImageInput'
                              accept='image/png, image/gif, image/jpeg'
                              onChange={e => {
                                setMessageType('image')
                                handleImageChange(e)
                              }}
                            />
                            <Icon name='addPhoto' color='white' />
                          </Button>
                          {currentImage && (
                            <Box
                              style={{
                                position: 'absolute',
                                bottom: '-5px',
                                left: '50px',
                              }}
                            >
                              <img
                                src={currentImage.url}
                                alt='preview'
                                style={{
                                  border: 'solid 2px #652d92',
                                  borderRadius: '4px',
                                  objectFit: 'contain',
                                  maxWidth: '200px',
                                  maxHeight: '200px',
                                  boxShadow: '0px 0px 0px 1px rgba(255, 255, 255, 0.5)',
                                }}
                              />
                            </Box>
                          )}
                        </Box>
                        <Box
                          style={{
                            marginTop: '8px',
                            maxHeight: '40px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#fff',
                            boxShadow: 'inset 0px 0px 0px 1px rgba(101, 45, 146, 0.5)',
                            borderRadius: '20px',
                          }}
                        >
                          <Button
                            variant='outlined'
                            size='small'
                            onClick={() => {
                              mobileFileRef.current.click()
                            }}
                            style={{
                              borderRadius: '50%',
                              minHeight: '40px',
                              minWidth: '40px',
                              maxWidth: '40px',
                              boxShadow: '0px 0px 15px 3px rgba(0, 0, 0, 0.25)',
                            }}
                          >
                            <input
                              hidden
                              type='file'
                              ref={mobileFileRef}
                              onChange={e => {
                                setCurrentImage(null)
                                setMessageType('file')
                                setCurrentFile(e.target.files[0])
                              }}
                            />
                            <Icon name='attachFile' color='white' />
                          </Button>
                          {currentFile && (
                            <Box
                              style={{
                                whiteSpace: 'nowrap',
                                maxWidth: '250px',
                                padding: '0px 10px',
                              }}
                            >
                              <Typography
                                variant='body1'
                                color='primary'
                                family='poppins'
                                style={{
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  width: '100%',
                                  maxHeight: '24px',
                                }}
                              >
                                {currentFile.name}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    )}
                  </Box>
                  <MessageForm>
                    <Box
                      style={{
                        width: '100vw',
                        backgroundColor: '#fff',
                      }}
                    >
                      <TextField
                        id='chatMessageBox'
                        name='message'
                        size='small'
                        margin='none'
                        placeholder='Message...'
                        fullWidth
                        multiline
                        style={{
                          padding: '0px 56px',
                        }}
                      />
                    </Box>
                    <Button
                      size='small'
                      type='submit'
                      style={{
                        color: '#fff',
                        position: 'absolute',
                        right: '8px',
                        bottom: '2px',
                        borderRadius: '50%',
                        minHeight: '40px',
                        minWidth: '40px',
                        maxWidth: '40px',
                        marginLeft: '16px',
                      }}
                    >
                      <Icon name='send' color='inherit' />
                    </Button>
                  </MessageForm>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        <Divider style={{ margin: '5px 0px' }} />
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '10px',
          }}
        >
          <Button
            size='small'
            onClick={() => {
              setFullScreen(true)
              document.body.style.overflow = 'hidden'
            }}
          >
            Start Chatting
          </Button>
        </Box>
      </Hidden>
    </>
  )
}

VoucherChat.propTypes = {
  chatID: PropTypes.string.isRequired,
}

export default VoucherChat
