import { pdf } from '@react-pdf/renderer'
import { saveAs } from 'file-saver'

// remove the question mark from the query string
export const generatePDF = async (template, fileName) => {
  const blob = await pdf(await template)?.toBlob()

  saveAs(blob, `${fileName}.pdf`)
}

export default generatePDF
