import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { v4 as uuidv4 } from 'uuid'

import { uploadFile } from '../firebase/storage'
import Modal from '../stories/Modal'
import Heading from '../stories/Heading'
import Divider from '../stories/Divider'
import Button from '../stories/Button'
import { useVoucherDetails } from '../context/VoucherDetails'
import { useCurrentProfile } from '../context/CurrentUser'

import {
  MDXEditor,
  toolbarPlugin,
  headingsPlugin,
  quotePlugin,
  listsPlugin,
  linkPlugin,
  linkDialogPlugin,
  tablePlugin,
  UndoRedo,
  BlockTypeSelect,
  CreateLink,
  InsertTable,
  ListsToggle,
  BoldItalicUnderlineToggles,
} from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css'

import DOMPurify from 'dompurify'

const AddNoteModal = ({ open, handleClose, noteType = 'text', onUpload }) => {
  const profile = useCurrentProfile()

  const [disabled, setDisabled] = useState(false)
  const [errorMsg, setErrorMsg] = useState(null)

  const [markdownNote, setMarkdownNote] = useState('')

  const { addTextNote, addFileNote, addImageNote, currentFile, setCurrentFile, voucher } =
    useVoucherDetails()

  const uploadNote = useCallback(async () => {
    try {
      setDisabled(true)
      setErrorMsg(null)

      const providerName = `${profile?.firstname || ''} ${profile?.lastname || ''}`

      const note = {
        content: null,
        patient: {
          firstName: voucher?.customer?.firstname?.toLowerCase()?.trim(),
          lastName: voucher?.customer?.lastname?.toLowerCase()?.trim(),
          id: voucher.user.id,
        },
        voucher: {
          code: voucher.code,
          id: voucher?.uid || voucher?.id || null,
          originalOrderId: voucher?.emrOrderID || voucher.orderId || null,
        },
        organization: {
          id: profile.organizationId,
          name: profile.organizationName,
          provider: providerName,
        },
      }
      console.log('note')
      console.log(note)

      if (noteType === 'text') {
        console.log('  -- text note')

        const safeNote = DOMPurify.sanitize(markdownNote)

        // TODO: add validation but also add fallback for doctors who dont have a display name
        note.content = safeNote
        console.log(note)
        await addTextNote(note)
      } else if (noteType === 'image') {
        const url = await uploadFile(`/notes/${voucher.code}/${uuidv4()}`, currentFile, {
          ownerID: profile.uid,
        })
        if (url !== null) {
          note.content = url
          note.fileName = currentFile.name
          addImageNote(note)
        }
        if (url.error) {
          if (url.error.code === 403) alert('Image with that name already exists!') // eslint-disable-line no-alert
        }
      } else if (noteType === 'file') {
        const url = await uploadFile(`/notes/${voucher.code}/${uuidv4()}`, currentFile, {
          ownerID: profile.uid,
        })
        if (url !== null) {
          note.content = url
          note.fileName = currentFile.name
          addFileNote(note)
        }
        if (url.error) {
          if (url.error.code === 403) alert('File with that name already exists!') // eslint-disable-line no-alert
        }
      }
      setDisabled(false)
      setErrorMsg(null)
      onUpload()
    } catch (error) {
      console.log('error')
      console.log(error)
      setDisabled(false)
      setErrorMsg('Failed to upload.')
    }
  }, [
    voucher,
    profile,
    noteType,
    onUpload,
    addTextNote,
    addImageNote,
    currentFile,
    addFileNote,
    markdownNote,
  ])

  return (
    <Modal open={open} onClose={handleClose} fullWidth maxWidth='md'>
      <style jsx>
        {' '}
        {`
          .mdxeditor-popup-container {
            z-index: 100001;
          }
        `}{' '}
      </style>
      <style jsx>
        {' '}
        {`
          ._contentEditable_yms4a_379 {
            height: 55vh;
          }
        `}{' '}
      </style>
      <Box>
        <Heading variant='h3' weight='bold' align='center'>
          Adding {noteType} note
        </Heading>
        {errorMsg && (
          <Typography variant='body1' align='center'>
            {errorMsg}
          </Typography>
        )}
        <Divider spacing='vertical' />
        {noteType === 'text' && (
          <Box
            mt={2}
            sx={{
              textAlign: 'start',
              minHeight: '60vh',
            }}
          >
            <MDXEditor
              markdown=''
              onChange={setMarkdownNote}
              plugins={[
                headingsPlugin(),
                quotePlugin(),
                listsPlugin(),
                linkPlugin(),
                linkDialogPlugin(),
                tablePlugin(),
                toolbarPlugin({
                  toolbarContents: () => (
                    <>
                      {' '}
                      <UndoRedo />
                      <BlockTypeSelect />
                      <BoldItalicUnderlineToggles />
                      <CreateLink />
                      <ListsToggle />
                      <InsertTable />
                    </>
                  ),
                }),
              ]}
            />
          </Box>
        )}
        {noteType === 'image' && (
          <>
            <Box mt={2}>
              <Button variant='contained' size='small' component='label' disabled={disabled}>
                Choose Image
                <input
                  type='file'
                  hidden
                  onChange={e => {
                    setCurrentFile(e.target.files[0])
                  }}
                />
              </Button>
            </Box>
            {currentFile && (
              <Box mt={2}>
                <Typography variant='body1' align='center'>
                  {currentFile.name}
                </Typography>
              </Box>
            )}
          </>
        )}
        {noteType === 'file' && (
          <>
            <Box mt={2}>
              <Button variant='contained' size='small' component='label' disabled={disabled}>
                Choose File
                <input
                  type='file'
                  hidden
                  onChange={e => {
                    setCurrentFile(e.target.files[0])
                  }}
                />
              </Button>
            </Box>
            {currentFile && (
              <Box mt={2}>
                <Typography variant='body1' align='center'>
                  {currentFile.name}
                </Typography>
              </Box>
            )}
          </>
        )}
      </Box>
      <Box display='flex' justifyContent='space-around' mt={4}>
        <Button
          id='cancel-note'
          disabled={disabled}
          size='small'
          variant='outlined'
          severity='danger'
          onClick={handleClose}
          fullWidth
        >
          Cancel
        </Button>
        <Button
          id='confirm-note'
          disabled={disabled}
          size='small'
          variant='outlined'
          onClick={uploadNote}
          fullWidth
        >
          Confirm
        </Button>
      </Box>
    </Modal>
  )
}

AddNoteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  noteType: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
}

export default AddNoteModal
