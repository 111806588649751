import styled, { css } from 'styled-components'
import MuiCard from '@mui/material/Card'
import MuiCardContent from '@mui/material/CardContent'
import MuiCardActions from '@mui/material/CardActions'
import MuiTypography from '@mui/material/Typography'

export const Card = styled(MuiCard)`
  position: relative;
  height: 100%;
  padding: ${props => props.theme.spacing(4)};
  background: ${props => props.theme.palette.background.paper};
  ${props =>
    props.spacing === 'thin' &&
    css`
      padding: ${props.theme.spacing(2)};
    `}
  ${props =>
    props.spacing === 'none' &&
    css`
      padding: ${props.theme.spacing(0)};
    `}
  ${props =>
    props.background === 'smoke' &&
    css`
      background: ${props.theme.palette.background.smoke};
    `}
`

export const CardContent = styled(MuiCardContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  background: inherit;
  text-align: center;
  &:last-child {
    padding: 0px;
  }
  ${props =>
    props.side === 'front' &&
    css`
      padding: ${props.theme.spacing(3)} 0px ${props.theme.spacing(2)};
      background: ${props.theme.palette.background.smoke};
      text-align: center;
    `}
  ${props =>
    props.side === 'back' &&
    css`
      align-items: flex-start;
      padding: ${props.theme.spacing(5)} 0px ${props.theme.spacing(2)};
      background: ${props.theme.palette.auxLight.dark};
      text-align: left;
    `}
`

export const CardActions = styled(MuiCardActions)`
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: ${props => props.theme.spacing(2)} 0px;
  border-top: 1px dashed ${props => props.theme.palette.primary.main};
`
export const CardTitle = styled(MuiTypography)`
  margin-bottom: ${props => props.theme.spacing(1)};
  font-size: 1.125rem;
  font-weight: 500;
  ${props =>
    props.transform === 'lowercase' &&
    css`
      text-transform: lowercase;
    `}
`

export const CardText = styled(MuiTypography)`
  margin-top: ${props => props.theme.spacing(1)};
  font-size: 0.875rem;
  ${props =>
    props.truncate &&
    css`
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    `}
  ${props =>
    props.truncate === 'small' &&
    css`
      -webkit-line-clamp: 3;
    `}
  ${props =>
    props.truncate === 'big' &&
    css`
      -webkit-line-clamp: 10;
    `}
`

export default Card
