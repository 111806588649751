import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'
import SignInForm from '../components/SignInForm'
import { signIn } from '../firebase/auth'
import { isEmail } from '../libs/validators'
import useErrors, { CODES } from '../hooks/useAuthErrors'

export const SignInFormContainer = ({ redirect = null, callback }) => {
  const [errors, , setError] = useErrors()
  const { search } = useLocation()
  const ref = new URLSearchParams(search).get('ref')
  const navigate = useNavigate()

  if (ref) {
    localStorage.setItem('refvalue', ref)
  }

  const onSubmit = useCallback(
    async event => {
      event.preventDefault()

      const { email, password } = event.target.elements
      if (isEmail(email.value)) {
        // if given email is valid
        // proceed with login
        try {
          await signIn(email.value, password.value).then(() => {
            // navigate to given path from redirect prop
            if (redirect) {
              navigate(redirect)
            }

            // if callback function is provided
            // call it
            if (callback) {
              console.log('callback')
              callback()
            }
          })
        } catch (error) {
          // firebase returned an error during sign in
          setError(error)
        }
      } else {
        // if given email is not valid
        // set validation error on form
        setError(CODES.INVALID_EMAIL)
      }
    },
    [setError, redirect, navigate],
  )

  return <SignInForm onSubmit={onSubmit} errors={errors} />
}

SignInFormContainer.propTypes = {
  redirect: PropTypes.string,
}

export default SignInFormContainer
