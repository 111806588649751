import PropTypes from 'prop-types'
import { Grid, Box } from '@mui/material'
import firebase from 'firebase/compat/app'
import { collection } from '../firebase/firestore'
import Typography from '../stories/Typography'
import Button from '../stories/Button'
import { useCurrentProfile } from '../context/CurrentUser'
import useNotes from '../hooks/useNotes'

const NotesAttributes = ({
  organization,
  timestamp,
  uid,
  originalOrderId = null,
  shared = [],
  showShare = true,
}) => {
  const { isProvider } = useCurrentProfile()
  const { setNotes } = useNotes()

  const shareWithReferringProvider = (noteID, orderID) => {
    console.log('Share with referring provider')
    console.log(noteID)
    console.log(orderID)

    collection('notes')
      .doc(noteID)
      .get()
      .then(async doc => {
        if (doc.exists) {
          console.log('Document data:', doc.data())

          let sharedStatus = shared

          if (sharedStatus) {
            // check if already shared
            if (!sharedStatus.includes(orderID)) {
              sharedStatus.push(orderID)
            }
          } else {
            sharedStatus = [orderID]
          }

          await doc.ref.update({ shared: sharedStatus })

          setNotes(prevNotes => {
            const newNotes = prevNotes.map(prevNote => {
              if (prevNote.uid === noteID) {
                return { ...prevNote, sharedStatus }
              }
              return prevNote
            })
            return newNotes
          })
        } else {
          // doc.data() will be undefined in this case
          console.log('No such document!')
          // eslint-disable-next-line no-alert
          alert(`Could not share, please contact support. NoteID: ${noteID} OrderID: ${orderID}`)
        }
      })
  }

  const stopShareWithReferringProvider = (noteID, orderID) => {
    console.log('Stop Share with referring provider')
    console.log(noteID)
    console.log(orderID)

    collection('notes')
      .doc(noteID)
      .get()
      .then(async doc => {
        if (doc.exists) {
          console.log('Document data:', doc.data())

          let sharedStatus = shared

          if (sharedStatus) {
            sharedStatus = sharedStatus.filter(item => item !== orderID)
          } else {
            sharedStatus = []
          }

          await doc.ref.update({ shared: sharedStatus })

          setNotes(prevNotes => {
            const newNotes = prevNotes.map(prevNote => {
              if (prevNote.uid === noteID) {
                return { ...prevNote, sharedStatus }
              }
              return prevNote
            })
            return newNotes
          })
        } else {
          // doc.data() will be undefined in this case
          console.log('No such document!')
          // eslint-disable-next-line no-alert
          alert(
            `Could not stop sharing, please contact support. NoteID: ${noteID} OrderID: ${orderID}`,
          )
        }
      })
  }

  return (
    <Grid container>
      <Grid item xs={10} sm={10} md={10} lg={10}>
        <Box>
          <Typography variant='body1'>Created by: {organization?.provider || ''}</Typography>
        </Box>
        <Box>
          <Typography variant='body1'>
            Created on:{' '}
            {timestamp instanceof firebase.firestore.Timestamp
              ? timestamp.toDate().toLocaleString()
              : timestamp.toLocaleString()}
          </Typography>
        </Box>
      </Grid>
      {isProvider && showShare && !shared?.length > 0 && (
        <Grid item xs={2} sm={2} md={2} lg={2}>
          {/* Button with icon, on hover will say Share with referring provider */}
          <Button
            variant='outlined'
            size='small'
            color='primary'
            title='Share with referring provider'
            onClick={() => {
              console.log('Share with referring provider')
              shareWithReferringProvider(uid, originalOrderId)
            }}
            style={{
              height: '40px',
              width: '100%',
            }}
          >
            Share
          </Button>
        </Grid>
      )}
      {isProvider && showShare && shared?.length > 0 && (
        <Grid item xs={2} sm={2} md={2} lg={2}>
          {/* Button with icon, on hover will say Share with referring provider */}
          <Button
            variant='outlined'
            size='small'
            color='primary'
            title='Stop sharing with referring provider'
            onClick={() => {
              console.log('Stop sharing with referring provider')
              stopShareWithReferringProvider(uid, originalOrderId)
            }}
            style={{
              height: '40px',
              width: '100%',
            }}
          >
            Unshare
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

NotesAttributes.propTypes = {
  organization: PropTypes.shape({
    provider: PropTypes.string,
  }).isRequired,
  timestamp: PropTypes.oneOfType([
    PropTypes.instanceOf(firebase.firestore.Timestamp),
    PropTypes.instanceOf(Date),
  ]).isRequired,
  uid: PropTypes.string.isRequired,
  originalOrderId: PropTypes.string,
  shared: PropTypes.arrayOf(PropTypes.string),
  showShare: PropTypes.bool,
}

export default NotesAttributes
