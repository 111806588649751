import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import MuiDialog from '@mui/material/Dialog'

export const StyledModal = styled(MuiDialog)`
  .MuiDialog-paper {
    text-align: center;
    padding: ${props => props.theme.spacing(3)};
    box-shadow: ${props => props.theme.shadows[2]};
    ${props => props.theme.breakpoints.up('sm')} {
      padding: ${props => props.theme.spacing(4)}px
        ${props => props.theme.spacing(5)}px
        ${props => props.theme.spacing(3)};
    }
    ${props =>
      props.height === 'large' &&
      css`
        height: 90vh;
      `}
      ${props =>
        props.width === 'large' &&
        css`
          width: 80vw;
          max-width: 80vw;
        `}
`

const Modal = ({ children, ...props }) => <StyledModal {...props}>{children}</StyledModal>

Modal.propTypes = {
  children: PropTypes.node,
}

export default Modal
