import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation, useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import Typography from '../stories/Typography'
import Link from '../stories/Link'
import PlanQuickLinks from '../components/Plans/PlanQuickLinks'
import NetworkHeroLogin from '../components/NetworkHeroLogin'
import NetworkHero from '../components/NetworkHero'
import NetworkCareTypesListNetwork from '../components/NetworkCareTypesListNetwork'
import { useCurrentUser, useCurrentProfile } from '../context/CurrentUser'
import { useCurrentNetwork } from '../context/CurrentNetwork'
import { parseQueryString } from '../libs/parseQueryStrings'
import LoadingBar from '../stories/RandomLoadingBar'
import NetworkCards from '../components/NetworkCards'

const NetworkPage = () => {
  const { search } = useLocation()
  const { network } = useParams()
  const [ref, setRef] = useState('')
  const [utms, setUtms] = useState({})
  const [isMember, setIsMember] = useState(false)

  const { uid } = useCurrentUser()
  const { isProvider, isAdmin, isPatient, organizationId } = useCurrentProfile()

  const {
    setAllUTMs,
    setUnformattedUTMs,
    loadNetworkBySlug,
    networkData,
    landerData,
    checkIfMember,
    networkId,
  } = useCurrentNetwork()

  // {* BEGIN Tracking Calls *}
  useEffect(() => {
    const { ...utmsObj } = parseQueryString(search)
    if (utmsObj.ref) {
      localStorage.setItem('refvalue', ref)
    }
    setRef(utmsObj.ref)
    setUtms(utmsObj)
    setUnformattedUTMs(search)
  }, [ref, search, setUnformattedUTMs])

  useEffect(() => {
    setAllUTMs(utms)
  }, [utms]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.Intercom('trackEvent', 'Page View', {
      page_name: 'Network Lander',
      network,
      RefCode: ref || '',
      ...utms,
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // {* END Tracking Calls *}

  // {* BEGIN Calls to fetch lander data *}
  useEffect(() => {
    async function GetNetwork() {
      await loadNetworkBySlug(network)
    }
    if (network && (!networkData?.slug || networkData?.slug !== network)) {
      GetNetwork()
    }
  }, [network, networkData]) // eslint-disable-line react-hooks/exhaustive-deps
  // {* END Calls to fetch lander data *}

  useEffect(() => {
    if (!networkData?.membershipRequired) {
      setIsMember(true)
      return undefined
    }

    const checkMembership = async () => {
      const member = await checkIfMember({
        userID: uid,
        clinicID: organizationId,
        networkID: networkId,
      })
      setIsMember(member)
    }

    if (!uid || !networkId) {
      setIsMember(false)
      return undefined
    }

    if (isAdmin) {
      setIsMember(true)
      return undefined
    }

    checkMembership()
  }, [networkId, isProvider, isAdmin, isPatient, uid, landerData]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Helmet>
        {networkData?.metaTitle && <title>Mishe | {networkData?.metaTitle || ''}</title>}
        {networkData?.metaTitle && (
          <meta property='og:title' content={`Mishe | ${networkData?.metaTitle || ''}`} />
        )}
        {!networkData?.metaTitle && <title>Mishe | {network}</title>}
        {!networkData?.metaTitle && <meta property='og:title' content={`Mishe | ${network}`} />}
        <meta
          name='description'
          property='og:description'
          content='Flexible treatment options, transparent pricing, modern payment tools, and your own personal care concierge team. Mishe is bringing healing back to healthcare'
        />
        <meta property='og:url' content={`https://www.mishe.co/plans/${network}`} />
        <link rel='canonical' href={`https://mishe.co/plans/${network}`} />
      </Helmet>
      {!uid && networkData && networkData?.loginRequired && (
        <NetworkHeroLogin reason='not-loggedIn' loginMethods={networkData?.loginMethods} />
      )}
      {uid && !isMember && (
        <NetworkHeroLogin reason='not-member' loginMethods={networkData?.loginMethods} />
      )}

      {((isMember && uid) || (networkData && !networkData?.loginRequired)) && (
        <>
          {landerData ? (
            <>
              {landerData?.error === 'network-not-found' && (
                <Box
                  style={{
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      width: '90%',
                    }}
                  >
                    <Typography variant='h4' align='center'>
                      We're having some trouble loading this plan at the moment.
                    </Typography>
                    <Typography variant='caption' align='center'>
                      Please try again later, if the issue persists please contact support.
                    </Typography>
                    <Link display='standard' to='/dashboard' variant='body1'>
                      Go to Dashboard
                    </Link>
                  </Box>
                </Box>
              )}
              {!landerData?.error && (
                <>
                  {landerData?.quickLinks && <PlanQuickLinks links={landerData?.quickLinks} />}
                  <NetworkHero landerData={landerData} networkSlug={network} />
                  <NetworkCards networkSlug={network} />
                  <NetworkCareTypesListNetwork networkSlug={network} networkData={networkData} />
                </>
              )}
            </>
          ) : (
            <Box
              style={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <LoadingBar />
            </Box>
          )}
        </>
      )}
    </>
  )
}

export default NetworkPage
