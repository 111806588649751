const lowerCode = (code, diff) => parseInt(code + '0'.repeat(diff), 10)
const upperCode = (code, diff) => parseInt(code + '9'.repeat(diff), 10)

export const startsWithNumber = (ref, key, value, diff) => {
  if (diff > 0) {
    return ref.where(key, '>=', lowerCode(value, diff)).where(key, '<=', upperCode(value, diff))
  }
  return ref.where(key, '==', value)
}

export const startsWithString = (ref, key, value) => {
  const endValue = value.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1))
  return ref.where(key, '>=', value).where(key, '<=', endValue)
}
