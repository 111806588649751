import { useEffect, useMemo, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useSearch } from '../context/Search'
import { searchForLocations } from '../libs/algolia'
import { collection } from '../firebase/firestore'

const fetchCollection = async slug =>
  collection('organizations').where('slug', '==', slug).limit(1).get()

const OrganizationFunction = ({ children }) => {
  const { form } = useSearch()
  const navigate = useNavigate()
  const { slug } = useParams()
  const { search } = useLocation()

  const [locations, setLocations] = useState([])
  const [organization, setOrganization] = useState({})

  const ref = new URLSearchParams(search).get('ref')
  if (ref) {
    localStorage.setItem('refvalue', ref)
  }

  useEffect(() => {
    window.Intercom('trackEvent', 'Page View', {
      page_name: 'Clinic Lander',
      slug,
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchCollection(slug)
      .then(snapshot => {
        if (!snapshot.empty) {
          snapshot.docs.forEach(doc => {
            const { image, ...data } = doc.data()
            setOrganization({
              ...data,
              image: image?.[0]?.downloadURL,
              uid: doc.id,
              id: doc.id,
            })
          })
        } else {
          // redirect to landing page if no organization found
          navigate('/')
        }
      })
      .catch(error => {
        console.log('******** There was an error ********')
        console.log(error.message)
      })
  }, [slug, navigate])

  useEffect(() => {
    if (organization.uid) {
      searchForLocations(organization.uid, form.zipcode)
        .then(response => setLocations(response.hits))
        .catch(e => {
          console.log(e)
          setLocations([])
        })
    }
  }, [organization]) // eslint-disable-line

  const primary = useMemo(() => locations.find(each => each.primary), [locations])

  return children(organization, locations, primary)
}

OrganizationFunction.propTypes = {
  children: PropTypes.func.isRequired,
}

export default OrganizationFunction
