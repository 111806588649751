import { useCallback, useState } from 'react'

export const DEFAULT_FORM = {
  query: '',
  zipcode: '',
  sortBy: 'by_distance',
  page: 0,
  type: null,
  tagFilters: undefined,
  typeFilters: undefined,
}

const useSearchForm = () => {
  const [form, setForm] = useState(DEFAULT_FORM)

  const changeForm = useCallback(value => {
    if (value.call) {
      // if callable use callback directly
      setForm(value)
    } else {
      // otherwise merge value with previous data
      setForm(previous => ({
        ...previous,
        page: 0, // reset page everytime by default
        ...value,
      }))
    }
  }, [])

  return [form, changeForm, setForm]
}

export default useSearchForm
