import { useState, useCallback } from 'react'
import Box from '@mui/material/Box'
import SelectField from '../stories/Select'
import Button from '../stories/Button'
import { updateOrCreateDocument } from '../firebase/firestore'
import Typography from '../stories/Typography'
import Icon from '../stories/Icons'
import Tooltip from '@mui/material/Tooltip'

const PreferencesForm = ({ uid, values }) => {
  const [preferredCommunicationMethod, setPreferredCommunicationMethod] = useState(
    values?.preferences?.preferredCommunicationMethod || '',
  )
  const [preferredLanguage, setPreferredLanguage] = useState(
    values?.preferences?.preferredLanguage || '',
  )
  const [preferredProviderReligiousBackground, setPreferredProviderReligiousBackground] = useState(
    values?.preferences?.preferredProviderReligiousBackground || '',
  )
  const [preferredProviderRaceEthnicity, setPreferredProviderRaceEthnicity] = useState(
    values?.preferences?.preferredProviderRaceEthnicity || '',
  )
  const [preferredProviderGender, setPreferredProviderGender] = useState(
    values?.preferences?.preferredProviderGender || '',
  )
  const [savingData, setSavingData] = useState('Save Changes')

  const processProfileUpdate = useCallback(async () => {
    setSavingData('Saving...')
    await updateOrCreateDocument('profiles', uid, {
      preferences: {
        preferredCommunicationMethod: preferredCommunicationMethod,
        preferredLanguage: preferredLanguage,
        preferredProviderReligiousBackground: preferredProviderReligiousBackground,
        preferredProviderRaceEthnicity: preferredProviderRaceEthnicity,
        preferredProviderGender: preferredProviderGender,
      },
    })
      .then(() => {
        console.log('this works too')
      })
      .catch(error => {
        console.warn('there was an error')
        console.error(error)
      })
    console.log('this does work after')
    setSavingData('Saved')
    const timeout = setTimeout(() => {
      setSavingData('Save Changes')
      clearTimeout(timeout)
    }, 3000)
  }, [
    preferredCommunicationMethod,
    preferredLanguage,
    preferredProviderReligiousBackground,
    preferredProviderRaceEthnicity,
    preferredProviderGender,
    uid,
  ])

  return (
    <Box>
      <Box>
        <Box display='flex' alignItems='center'>
          <Typography variant='h5' margin='0'>
            Communication Preferences
          </Typography>
          <Tooltip
            title='This is the preferred method of communication for the patient. We will use this method to send reminders, updates, and other important information.'
            placement='top'
            arrow
            enterTouchDelay={0}
          >
            <Box marginLeft='8px'>
              <Icon name='info' />
            </Box>
          </Tooltip>
        </Box>
        <SelectField
          label='Preferred Communication Method'
          onChange={e => setPreferredCommunicationMethod(e.target.value)}
          value={preferredCommunicationMethod}
          options={[
            { value: 'email', label: 'Email' },
            { value: 'phone', label: 'Phone' },
            { value: 'sms', label: 'SMS' },
          ]}
        />
        <Box display='flex' alignItems='center'>
          <Typography variant='h5' margin='0'>
            Preferred Language
          </Typography>
          <Tooltip
            title='This is the preferred language of the patient. We will use this language to communicate with the patient.'
            placement='top'
            arrow
            enterTouchDelay={0}
          >
            <Box marginLeft='8px'>
              <Icon name='info' />
            </Box>
          </Tooltip>
        </Box>
        <SelectField
          label='Language'
          onChange={e => setPreferredLanguage(e.target.value)}
          value={preferredLanguage}
          options={[
            { value: 'en', label: 'English' },
            { value: 'es', label: 'Spanish' },
            { value: 'fr', label: 'French' },
          ]}
        />
      </Box>
      <Box>
        <Box display='flex' alignItems='center'>
          <Typography variant='h5' margin='0'>
            Provider Preferences
          </Typography>
          <Tooltip
            title='These are the preferred provider characteristics for the patient. We will use this information to match the patient with the best provider.'
            placement='top'
            arrow
            enterTouchDelay={0}
          >
            <Box marginLeft='8px'>
              <Icon name='info' />
            </Box>
          </Tooltip>
        </Box>
        {/* <SelectField
          label='Preferred Culture'
          options={
            [
              { value: 'en', label: 'English' },
              { value: 'es', label: 'Spanish' },
              { value: 'fr', label: 'French' }
            ]
          } /> */}
        <SelectField
          label='Preferred Provider Religious Background'
          onChange={e => setPreferredProviderReligiousBackground(e.target.value)}
          value={preferredProviderReligiousBackground}
          options={[
            { value: 'noPreference', label: 'No Preference' },
            { value: 'christian', label: 'Christian' },
            { value: 'jewish', label: 'Jewish' },
            { value: 'muslim', label: 'Muslim' },
            { value: 'buddhist', label: 'Buddhist' },
            { value: 'hindu', label: 'Hindu' },
            { value: 'sikh', label: 'Sikh' },
            { value: 'atheistAgnostic', label: 'Atheist/Agnostic' },
            { value: 'other', label: 'Other' },
          ]}
        />
        <SelectField
          label='Preferred Provider Race/Ethnicity'
          onChange={e => setPreferredProviderRaceEthnicity(e.target.value)}
          value={preferredProviderRaceEthnicity}
          options={[
            { value: 'noPreference', label: 'No Preference' },
            {
              value: 'americanIndian',
              label: 'American Indian or Alaska Native',
            },
            { value: 'asian', label: 'Asian' },
            { value: 'black', label: 'Black or African American' },
            { value: 'hispanic', label: 'Hispanic or Latino' },
            {
              value: 'nativeHawaiian',
              label: 'Native Hawaiian or Other Pacific Islander',
            },
            { value: 'white', label: 'White' },
          ]}
        />
        <SelectField
          label='Preferred Provider Gender'
          onChange={e => setPreferredProviderGender(e.target.value)}
          value={preferredProviderGender}
          options={[
            { value: 'noPreference', label: 'No Preference' },
            { value: 'male', label: 'Male' },
            { value: 'female', label: 'Female' },
            { value: 'nonBinary', label: 'Non-Binary' },
            { value: 'other', label: 'Other' },
          ]}
        />
      </Box>
      <Box mt={2}>
        <Button fullWidth onClick={processProfileUpdate} variant='contained' color='primary'>
          {savingData}
        </Button>
      </Box>
    </Box>
  )
}

export default PreferencesForm
