import { createContext, useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import useNetworks from '../hooks/useNetworks'

import { retrieveFiretableImageURL } from '../libs/formatters'

import { useWhiteLabelingContext } from './WhiteLabelingContext'

const CurrentNetwork = createContext()

export const useCurrentNetwork = () => useContext(CurrentNetwork)

export const CurrentNetworkProvider = ({ children }) => {
  const {
    getNetworkByName,
    getNetworkBySlug,
    getNetworkByID,
    getServicesByMisheCode,
    getServicesBySpecialtyTag,
    getClinicLocations,
    getLanderData,
    checkIfMember,
  } = useNetworks()

  const { setLogoSource, setPlanColorPrimary, setPlanColorSecondary } = useWhiteLabelingContext()

  const [networkId, setNetworkId] = useState(null)
  const [networkData, setNetworkData] = useState(null)
  const [landerData, setLanderData] = useState(null)
  const [serviceList, setServiceList] = useState(null)
  const [currentServices, setCurrentServices] = useState(null)
  const [clinics, setClinics] = useState(null)
  const [clinicLocations, setClinicLocations] = useState(null)
  const [allUTMs, setAllUTMs] = useState({})
  const [unformattedUTMs, setUnformattedUTMs] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function loadLanderData() {
      if (!networkId) {
        return undefined
      }
      setLoading(true)

      if (networkId === 'permission-denied') {
        setLanderData({ error: 'permission-denied' })
        return undefined
      }

      if (networkId === 'not-found') {
        setLanderData({ error: 'network-not-found' })
        return undefined
      }

      const response = await getLanderData(networkId)
      setLanderData(response)
      setLoading(false)
    }
    loadLanderData()
  }, [networkId]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const getOrgLocs = async () => {
      console.log('context - getting org locs')
      if (!currentServices || currentServices?.error) {
        return undefined
      }
      // get all org ids from services and remove duplicates
      const validClinics = await currentServices
        .map(service => {
          if (service.bundle) {
            const clinicIDs = service.bundleComponents.map(bundleComponent => {
              return bundleComponent.organization.uid
            })
            return clinicIDs
          }

          return service.organizationId
        })
        .flat()

      const uniqueClinics = [...new Set(validClinics)]

      console.log('context - uniqueClinics', uniqueClinics)

      setClinics(uniqueClinics)

      // Get all locations for each clinic and add the services offered to the clinic based on ID
      const allLocations = await Promise.all(
        uniqueClinics.map(async clinicID => {
          const locationsRes = await getClinicLocations(clinicID)

          console.log('****** context - locationsRes', locationsRes)

          // First filter for services only from the current clinic
          // Then check if any of the services from this clinic are not on the network
          let notOnNetwork = false

          console.log('****** context - currentServices', currentServices)
          const services = currentServices.filter(service => {
            if (service.bundle) {
              return service.bundleComponents.some(bundleComponent => {
                return bundleComponent.organization.uid === clinicID
              })
            }
            return service.organizationId === clinicID
          })

          let clinic = null
          if (services.length !== 0) {
            // Bundle Service
            if (services[0].bundle) {
              clinic = services[0].bundleComponents[0].organization
              services.forEach(service => {
                if (service.notOnNetwork) {
                  notOnNetwork = true
                }
              })
            } else {
              // Standard Service
              clinic = services[0]?.organization
              services.forEach(service => {
                if (service.notOnNetwork) {
                  notOnNetwork = true
                }
              })
            }
          }

          return { ...locationsRes, services, notOnNetwork, clinic }
        }),
      )

      setClinicLocations(allLocations)
    }
    getOrgLocs()
  }, [currentServices]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log('context - lander data updated')
    if (!landerData) {
      console.log('context - No lander data')
      return undefined
    }
    console.log(landerData)
  }, [landerData])

  useEffect(() => {
    console.log('context - network data updated')
    if (networkData?.error || networkData?.code) {
      console.warn(' -- context error with network data')
      console.warn(networkData)
      return undefined
    }
    if (!networkData) {
      console.error('  ** context - No network data')
      return undefined
    }
    console.log('  ====== -- networkData ', networkData)
    const customLogoURL = retrieveFiretableImageURL(networkData?.logoSource)
    console.log(' =========  -- context - customLogoURL', customLogoURL)
    if (customLogoURL) {
      console.log('  -- context - setting logo source')
      setLogoSource(customLogoURL)
    }
    const planColorPrimary = networkData?.planTheme?.primaryColor
    console.log('  -- context - planColorPrimary', planColorPrimary)
    if (planColorPrimary) {
      console.log('  -- context - setting plan primary color')
      setPlanColorPrimary(planColorPrimary)
    }
    const planColorSecondary = networkData?.planTheme?.secondaryColor
    console.log('  -- context - planColorSecondary', planColorSecondary)
    if (planColorSecondary) {
      console.log('  -- context - setting plan secondary color')
      setPlanColorSecondary(planColorSecondary)
    }
  }, [networkData])

  useEffect(() => {
    console.log('  -- context - current services updated')
    console.log(currentServices)
    if (!currentServices) {
      console.log('  ** context - No current services')
    }
  }, [currentServices])

  const loadNetworkBySlug = async slug => {
    setLoading(true)
    const network = await getNetworkBySlug(slug)

    console.log(' =====network by slug')
    console.log(network)

    if (!network) {
      setLoading(false)
      return null
    }

    if (network.code) {
      setLoading(false)
      setNetworkData(network)
      setNetworkId(network.code)
      return network.code
    }

    setNetworkData(network)
    setNetworkId(network.uid)
    setLoading(false)
  }

  const loadNetworkByName = async name => {
    setLoading(true)
    const network = await getNetworkByName(name)
    setNetworkData(network)
    setNetworkId(network.uid)
    setLoading(false)
  }

  const loadNetowrkByID = async id => {
    setLoading(true)
    const network = await getNetworkByID(id)
    setNetworkData(network)
    setNetworkId(network.uid)
    setLoading(false)
  }

  const loadServicesBySpecialtyTag = async tag => {
    setLoading(true)
    const services = await getServicesBySpecialtyTag(tag)
    setServiceList(services)
    setLoading(false)
  }

  const loadServicesByMisheCode = async ({ netId, misheCode }) => {
    setLoading(true)
    const services = await getServicesByMisheCode({ netId, misheCode })
    console.log('services by misheCode')
    console.log(services)
    setCurrentServices(services)
    setLoading(false)
  }

  const value = {
    setNetworkId,
    networkId,
    loadNetworkBySlug,
    loadNetworkByName,
    loadNetowrkByID,
    networkData,
    loadServicesBySpecialtyTag,
    serviceList,
    loadServicesByMisheCode,
    setCurrentServices,
    currentServices,
    landerData,
    loading,
    setAllUTMs,
    allUTMs,
    setUnformattedUTMs,
    unformattedUTMs,
    clinics,
    clinicLocations,
    checkIfMember,
  }

  return <CurrentNetwork.Provider value={value}>{children}</CurrentNetwork.Provider>
}

CurrentNetworkProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CurrentNetwork
