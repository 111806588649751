import { useState, useCallback } from 'react'
// import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { GENDER_OPTIONS } from '../libs/validators'
import { updateOrCreateDocument } from '../firebase/firestore'
import PhoneField from '../stories/PhoneInput'
import SelectField from '../stories/Select'
import TextField from '../stories/TextField'
import DateField from '../stories/DateField'
import Button from '../stories/Button'
import { Collapse } from '@mui/material'

const ProfileForm = ({ uid, values }) => {
  const [firstname, setFirstname] = useState(values?.firstname || '')
  const [lastname, setLastname] = useState(values?.lastname || '')
  const [phone, setPhone] = useState(values?.phone || '')
  const [birthday, setBirthday] = useState(values?.birthday || '')
  const [gender, setGender] = useState(values?.gender || '')
  const [raceEthnicity, setRaceEthnicity] = useState(values?.raceEthnicity || [])
  const [insured, setInsured] = useState(values?.insuranceInfo?.insured || false)
  const [insuranceProvider, setInsuranceProvider] = useState(
    values?.insuranceInfo?.insuranceProvider || '',
  )
  const BIRTHDAY_REGEX = /^([0-9]{2})-([0-9]{2})-([0-9]{4})$/
  const BIRTHDAY_REGEX_UNIVERSAL = /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/
  const [savingData, setSavingData] = useState('Save Changes')

  const processProfileUpdate = useCallback(async () => {
    setSavingData('Saving...')
    await updateOrCreateDocument('profiles', uid, {
      firstname: firstname,
      lastname: lastname,
      phone: phone,
      birthday: birthday,
      gender: gender,
      raceEthnicity: raceEthnicity,
      insuranceInfo: {
        insured: insured,
        insuranceProvider: insured ? insuranceProvider : '',
      },
    })
      .then(() => {
        console.log('this works too')
      })
      .catch(error => {
        console.warn('there was an error')
        console.error(error)
      })
    console.log('this does work after')
    setSavingData('Saved')
    const timeout = setTimeout(() => {
      setSavingData('Save Changes')
      clearTimeout(timeout)
    }, 3000)
  }, [firstname, lastname, phone, birthday, gender, raceEthnicity, insured, insuranceProvider, uid])

  const handleChange = event => {
    const {
      target: { value },
    } = event
    setRaceEthnicity(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value === undefined ? '' : value,
    )
  }

  let formattedBirthday = ''
  if (BIRTHDAY_REGEX.test(birthday)) {
    const [month, day, year] = birthday.split('-')
    formattedBirthday = `${year}-${month}-${day}`
  } else if (BIRTHDAY_REGEX_UNIVERSAL.test(birthday)) {
    formattedBirthday = birthday
  }

  return (
    <Box>
      <TextField
        required
        name='firstname'
        label='first name'
        value={firstname}
        onChange={e => setFirstname(e.target.value)}
      />
      <TextField
        required
        name='lastname'
        label='last name'
        value={lastname}
        onChange={e => setLastname(e.target.value)}
      />
      <PhoneField
        required
        name='phone'
        label='Telephone number'
        value={phone}
        onChange={e => setPhone(e.target.value)}
      />
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid item xs={12} sm={7}>
          <DateField
            required
            name='birthday'
            label='birthday'
            value={formattedBirthday}
            onChange={e => setBirthday(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <SelectField
            required
            label='gender'
            name='gender'
            options={GENDER_OPTIONS}
            value={gender}
            onChange={e => setGender(e.target.value)}
          />
        </Grid>
      </Grid>
      <SelectField
        label='Race/Ethnicity'
        name='raceEthnicity'
        multiple
        value={raceEthnicity}
        onChange={handleChange}
        options={[
          { value: 'preferNotToSay', label: 'Prefer not to say' },
          {
            value: 'americanIndian',
            label: 'American Indian or Alaska Native',
          },
          { value: 'asian', label: 'Asian' },
          { value: 'hispanic', label: 'Hispanic or Latino' },
          { value: 'black', label: 'Black or African American' },
          {
            value: 'nativeHawaiian',
            label: 'Native Hawaiian or Other Pacific Islander',
          },
          { value: 'white', label: 'White' },
          { value: 'other', label: 'Other' },
        ]}
      />
      <SelectField
        label='Insured'
        name='insured'
        value={insured}
        onChange={e => {
          setInsured(e.target.value)
        }}
        options={[
          { value: false, label: 'Not Insured' },
          { value: true, label: 'Insured' },
        ]}
      />
      <Collapse in={insured} timeout={500} unmountOnExit>
        <TextField
          name='insuranceProvider'
          label='Insurance Provider'
          value={insuranceProvider}
          onChange={e => {
            setInsuranceProvider(e.target.value)
          }}
        />
      </Collapse>
      {/* <TextField
        required
        name='addressStreet'
        label='street'
        defaultValue={values?.address?.street}
        helperText={errors?.address?.street}
      />
      <TextField
        required
        name='addressCity'
        label='City'
        defaultValue={values?.address?.city}
        helperText={errors?.address?.city}
      />
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid item xs={12} sm={6}>
          <Box paddingTop={2}>
            <StateSelectInput
              required
              name='State'
              label='State'
              variant='outlined'
              fullWidth
              onChange={setState}
              state={state}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            name='addressZip'
            label='Zip Code'
            defaultValue={values?.address?.zip}
            helperText={errors?.address?.zip}
          />
        </Grid>
      </Grid> */}
      <Box mt={2}>
        <Button fullWidth onClick={processProfileUpdate} variant='contained' color='primary'>
          {savingData}
        </Button>
      </Box>
    </Box>
  )
}

// ProfileForm.propTypes = {
//   onSubmit: PropTypes.func.isRequired,
//   onChange: PropTypes.func.isRequired,
//   notification: PropTypes.shape({
//     message: PropTypes.string,
//     severity: PropTypes.string
//   }),
//   errors: PropTypes.shape({
//     birthday: PropTypes.string,
//     firstname: PropTypes.string,
//     gender: PropTypes.string,
//     lastname: PropTypes.string,
//     phone: PropTypes.string,
//     address: PropTypes.shape({
//       city: PropTypes.string,
//       state: PropTypes.string,
//       street: PropTypes.string,
//       zip: PropTypes.string
//     })
//   }),
//   values: PropTypes.shape({
//     birthday: PropTypes.string,
//     firstname: PropTypes.string,
//     gender: PropTypes.string,
//     lastname: PropTypes.string,
//     phone: PropTypes.string,
//     address: PropTypes.shape({
//       city: PropTypes.string,
//       state: PropTypes.string,
//       street: PropTypes.string,
//       zip: PropTypes.string
//     })
//   })
// }

export default ProfileForm
