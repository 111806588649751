import { createContext, useContext, useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { collection } from '../firebase/firestore'
import useNotes from '../hooks/useNotes'
import useEmrOrders from '../hooks/useEmrOrders'

const VoucherDetails = createContext()

export const useVoucherDetails = () => useContext(VoucherDetails)

const VoucherDetailsProvider = ({ children }) => {
  // TODO change voucher to voucherdetails
  const { addFileNote, addImageNote, addTextNote, notes, setNotes } = useNotes()
  const { orders, setOrders, updateOrders } = useEmrOrders({})
  const [voucher, setVoucher] = useState(null)
  const [subVoucher, setSubVoucher] = useState(null)
  const [originalOrder, setOriginalOrder] = useState(null)
  const [currentFile, setCurrentFile] = useState(null)

  const handleClick = useCallback(passedVoucher => setVoucher(passedVoucher), [setVoucher])
  const handleClose = useCallback(() => {
    setVoucher(null)
    setOriginalOrder(null)
    setNotes([])
    setOrders([])
  }, [setVoucher, setNotes, setOrders])

  // TODO Combine all calls into one promise array and await

  const getNotes = useCallback(async () => {
    const notesSanpshot = await collection('notes')
      .where('voucher.code', '==', voucher.code)
      .orderBy('timestamp', 'desc')
      .get()
      .catch(err => {
        console.log(err)
        setNotes([])
      })
    if (notesSanpshot !== undefined && notesSanpshot.docs.length > 0) {
      setNotes(notesSanpshot.docs.map(each => ({ ...each.data(), uid: each.id })))
    } else {
      setNotes([])
    }
  }, [voucher, setNotes])

  useEffect(() => {
    const triggerNotes = async () => {
      if (!voucher) {
        return undefined
      }
      getNotes()
    }
    triggerNotes()
  }, [getNotes, voucher])

  const getOrders = useCallback(async () => {
    const ordersSanpshot = await collection('emrOrders')
      .where('sourceVoucherId', '==', voucher.uid || voucher.id)
      .orderBy('timestamp', 'desc')
      .get()
      .catch(err => {
        console.log(err)
        setOrders([])
        return null
      })

    if (ordersSanpshot !== undefined && ordersSanpshot?.docs?.length > 0) {
      setOrders(ordersSanpshot.docs.map(each => ({ ...each.data(), uid: each.id })))
    } else {
      setOrders([])
    }
  }, [voucher, setOrders])

  useEffect(() => {
    const triggerOrders = async () => {
      if (!voucher) {
        return undefined
      }
      getOrders()
    }
    triggerOrders()
  }, [getOrders, voucher])

  // need to update for bundles
  const getOriginalOrder = useCallback(
    async orderId => {
      const originalOrderSnapshot = await collection('emrOrders')
        .doc(orderId)
        .get()
        .catch(err => {
          console.log(err)
          setOriginalOrder(null)
        })

      if (!originalOrderSnapshot?.exists) {
        console.log('No original order')
        setOriginalOrder(null)
        return
      }
      console.log('Got original order')
      setOriginalOrder({
        ...originalOrderSnapshot.data(),
        id: originalOrderSnapshot.id,
      })
    },
    [setOriginalOrder],
  )

  useEffect(() => {
    if (!voucher) {
      console.log('Voucher Details - no voucher selected')
      return undefined
    }
    if (!voucher.emrOrderID && !voucher.orderId) {
      console.log('Voucher Details - no orderID on voucher')
      return undefined
    }
    getOriginalOrder(voucher.emrOrderID || voucher.orderId)
  }, [voucher, getOriginalOrder])

  const value = {
    voucher,
    notes,
    orders,
    setOrders,
    updateOrders,
    currentFile,
    originalOrder,
    setCurrentFile,
    handleClick,
    handleClose,
    setVoucher,
    addFileNote,
    addImageNote,
    addTextNote,
  }
  return <VoucherDetails.Provider value={value}>{children}</VoucherDetails.Provider>
}

VoucherDetailsProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default VoucherDetailsProvider
