import { useCallback } from 'react'
import { useVoucherDetails } from '../context/VoucherDetails'
import { useNotesModal } from '../context/AddNoteModal'
import Modal from '../components/AddNoteModal'

const AddNoteModal = () => {
  const { setCurrentFile } = useVoucherDetails() // eslint-disable-line no-unused-vars
  const { open, handleClose, noteType } = useNotesModal() // eslint-disable-line no-unused-vars

  const onUpload = useCallback(async () => {
    setCurrentFile(null)
    handleClose()
  }, [handleClose, setCurrentFile])

  return <Modal open={open} noteType={noteType} handleClose={handleClose} onUpload={onUpload} />
}

export default AddNoteModal
