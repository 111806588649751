import { createContext, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { subscribe } from '../firebase/auth'
import useUser from '../hooks/useUser'

const CurrentUser = createContext()
const CurrentProfile = createContext()
const CurrentClinic = createContext()

export const useCurrentUser = () => useContext(CurrentUser)
export const useCurrentProfile = () => useContext(CurrentProfile)
export const useCurrentClinic = () => useContext(CurrentClinic)

export const CurrentUserProvider = ({ children }) => {
  const [user, profile, login, logout, refresh, refreshProfile, clinic] = useUser()

  useEffect(() => {
    subscribe(value => {
      if (value) {
        login(value)
          .then(() => {
            console.log('******** User logged in ********') // eslint-disable-line
            refresh()
            refreshProfile().then(() => {
              console.log('******** Profile refreshed ********') // eslint-disable-line
              if (window.Intercom) {
                window.Intercom('trackEvent', 'Login', {
                  email: user?.email || value?.email || '',
                  phone: user?.phoneNumber || value?.phoneNumber || '',
                  uid: user?.uid || value?.uid || '',
                })
              }
            })
          })
          .catch(error => {
            console.log('******** There was an error ********') // eslint-disable-line
            console.log(error.message) // eslint-disable-line
          })
      } else {
        logout()
      }
    })
  }, [login, logout])

  return (
    <CurrentUser.Provider value={{ refresh, refreshProfile, ...user }}>
      <CurrentProfile.Provider value={profile}>
        <CurrentClinic.Provider value={clinic}>{children}</CurrentClinic.Provider>
      </CurrentProfile.Provider>
    </CurrentUser.Provider>
  )
}

CurrentUserProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CurrentUser
