import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useCurrentProfile } from '../context/CurrentUser'
import { useVoucherDetails } from '../context/VoucherDetails'
import Button from '../stories/Button'
import Typography from '../stories/Typography'

const ViewVoucherDetailsButton = ({
  voucher = {
    uid: '',
    code: '',
    service: {
      name: '',
    },
    customer: {
      lastname: '',
    },
  },
}) => {
  const { acceptedTerms, isProvider } = useCurrentProfile()
  const { handleClick } = useVoucherDetails()

  // console.log(' --voucher', voucher)

  const onClick = useCallback(() => {
    if (acceptedTerms || !isProvider) {
      handleClick(voucher)
    }
  }, [acceptedTerms, isProvider, voucher]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Button
      size='small'
      variant='outlined'
      onClick={onClick}
      disabled={!acceptedTerms && isProvider}
    >
      <Typography variant='body1' family='poppins'>
        View Details
      </Typography>
    </Button>
  )
}

ViewVoucherDetailsButton.propTypes = {
  voucher: PropTypes.shape({
    uid: PropTypes.string,
    code: PropTypes.string.isRequired,
    service: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    customer: PropTypes.shape({
      lastname: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default ViewVoucherDetailsButton
