import { useCallback, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Form from '../components/ConfirmPasswordForm'
import Notification from '../components/NotificationBox'
import ResendEmailForm from '../components/ResendEmailForm'
import { confirmPassword, sendPasswordResetEmailWithOrg } from '../firebase/auth'

const ConfirmPasswordForm = () => {
  const [notification, setNotification] = useState(null)
  const [errorNotification, setError] = useState(null)
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const { search } = useLocation()
  const navigate = useNavigate()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const emailForResend = params.get('email')

  const onSubmit = useCallback(async event => {
    // eslint-disable-line
    event.preventDefault()
    const { password } = event.target.elements
    if (password.value) {
      if (password.value.length < 8) {
        setNotification({
          severity: 'error',
          message: 'Password must be at least 8 characters long.',
        })
        return
      }
      if (password.value.length > 64) {
        setNotification({
          severity: 'error',
          message: 'Password must be less than 64 characters long.',
        })
        return
      }
      if (password.value === password.value.toLowerCase()) {
        setNotification({
          severity: 'error',
          message: 'Password must contain at least one uppercase letter.',
        })
        return
      }
      if (password.value === password.value.toUpperCase()) {
        setNotification({
          severity: 'error',
          message: 'Password must contain at least one lowercase letter.',
        })
        return
      }
      if (!/\d/.test(password.value)) {
        setNotification({
          severity: 'error',
          message: 'Password must contain at least one number.',
        })
        return
      }
      // dash needs to be at the end of the regex to avoid being interpreted as a range
      if (!/[!@#$%^&*_+?=-]/.test(password.value)) {
        setNotification({
          severity: 'error',
          message: 'Password must contain at least one special character, from the following: !@#$%^&*_+?=-',
        })
        return
      }
      if (password.value !== confirmNewPassword) {
        setNotification({
          severity: 'error',
          message: 'Passwords do not match.',
        })
        return
      }
      try {
        const oobCode = new URLSearchParams(search).get('oobCode')
        await confirmPassword(oobCode, password.value)
        // show success notification optimistically
        setNotification({
          severity: 'success',
          message: 'You can now sign in with your new password.',
        })
        setTimeout(() => {
          navigate('/signin')
        }, 1500)
      } catch (error) {
        setError({
          severity: 'error',
          message: error.message,
        })
      }
    }
  })

  const onEmailSubmit = useCallback(async event => {
    // eslint-disable-line
    event.preventDefault()
    const { email } = event.target.elements
    if (email.value) {
      try {
        await sendPasswordResetEmailWithOrg(email.value)
        // show success notification optimistically
        setNotification({
          severity: 'success',
          message: 'You should recieve an email shortly.',
        })
        setTimeout(() => {
          navigate('/signin')
        }, 5000)
      } catch (error) {
        setError({
          severity: 'error',
          message: error.message,
        })
        setNotification({
          severity: 'error',
          message:
            'Email not found, please make sure this is the email associated with your mishe account.',
        })
      }
    }
  })

  const onChange = useCallback(() => {
    setNotification(null)
  }, [])

  return (
    <>
      {notification && <Notification {...notification} />}
      {errorNotification == null && (
        <Form
          onChange={onChange}
          onSubmit={onSubmit}
          setConfirmNewPassword={setConfirmNewPassword}
        />
      )}
      {errorNotification && (
        <ResendEmailForm onChange={onChange} onSubmit={onEmailSubmit} email={emailForResend} />
      )}
    </>
  )
}

export default ConfirmPasswordForm
