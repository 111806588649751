import { useCallback, useState } from 'react'
import { collection, collectionModular, subCollectionModular } from '../firebase/firestore'
import { query, where, getDocs, and, or, orderBy, limit } from 'firebase/firestore'
import { useCurrentProfile } from '@app/context/CurrentUser'
import { useVouchers } from '@app/context/Vouchers'

const useVouchersFilter = () => {
  const { organizationId } = useCurrentProfile()
  const { setVouchers, setVouchersLoading } = useVouchers()

  const fetchVouchersFilter = useCallback(
    async ({ userInputQuery, date, status }) => {
      try {
        setVouchersLoading(true)
        const constructQuery = [where('organization.id', '==', organizationId)]
        let constructQueryOrder = [orderBy('createdAt', 'desc'), limit(30)]
        let constructQueryBundleOrder = [orderBy('createdAt', 'desc'), limit(30)]

        const constructQueryBundle = [
          where('bundle', '==', true),
          where('clinicIDs', 'array-contains', organizationId),
        ]
        console.log('status:', status, date, userInputQuery)

        if (status && status !== 'all') {
          console.log('status', status)
          constructQuery.push(where('status', '==', status))
        }

        if (date) {
          //  need index again
          console.log('date', date)
          constructQuery.push(where('customer.birthday', '==', date))
        }

        if (userInputQuery) {
          console.log('userInputQuery>>>', userInputQuery)
          if (parseInt(userInputQuery, 10)) {
            // need index again

            inputQueryWhere(constructQuery, 'code', userInputQuery)
          } else if (userInputQuery.startsWith('pi_')) {
            // need index again
            inputQueryWhere(constructQuery, 'orderId', userInputQuery)
          } else {
            // need index again

            inputQueryWhere(
              constructQuery,
              'customer.lastname',
              userInputQuery?.toLowerCase() || userInputQuery,
            )
          }
          constructQueryOrder = []
        }

        const voucherQuery = query(
          collectionModular('vouchers'),
          or(and(...constructQuery), and(...constructQueryBundle)),
          ...constructQueryOrder,
        )

        let vouchersDataFilter = []
        let countLoop = 0

        getDocs(voucherQuery).then(async function (voucherSnapshot) {
          let totalDocs = voucherSnapshot.docs.length
          console.log('totalDocstotalDocstotalDocs', totalDocs)
          for await (let doc of voucherSnapshot.docs) {
            const voucher = {
              ...doc.data(),
              id: doc.id,
              uid: doc.id
            }
            
            countLoop++

            if (voucher.bundle) {
              const constructSubQueryBundle = [
                where('organization.id', '==', organizationId),
                where('bundleID', '==', voucher.bundleID),
              ]

              if (status && status !== 'all') {
                constructSubQueryBundle.push(where('status', '==', status))
              }
              if (date) {
                constructSubQueryBundle.push(where('customer.birthday', '==', date))
              }

              if (userInputQuery) {
                if (parseInt(userInputQuery, 10)) {
                  // need index again
                  inputQueryWhere(constructSubQueryBundle, 'code', userInputQuery)
                } else if (userInputQuery.startsWith('pi_')) {
                  // need index again
                  inputQueryWhere(constructSubQueryBundle, 'orderId', userInputQuery)
                } else {
                  // indexes should be created
                  inputQueryWhere(
                    constructSubQueryBundle,
                    'customer.lastname',
                    userInputQuery?.toLowerCase() || userInputQuery,
                  )
                }
                constructQueryBundleOrder = []
              }

              const subVoucherQuery = query(
                subCollectionModular({
                  docName: 'vouchers',
                  docId: doc.id,
                  subDocName: 'subVouchers',
                }),
                ...constructSubQueryBundle,
                ...constructQueryBundleOrder,
              )
              const querySnapshotSubVoucherDoc = await getDocs(subVoucherQuery)

              let subVoucherLoop = 0
              const subVoucherData = []
              const totalSubVoucher = querySnapshotSubVoucherDoc.docs.length
              console.log('totalSubVoucher---', totalSubVoucher)
              totalDocs = totalDocs + totalSubVoucher
              querySnapshotSubVoucherDoc.forEach(subVoucherDoc => {
                subVoucherLoop++
                countLoop++

                const subVoucher = {
                  ...subVoucherDoc.data(),
                  id: subVoucherDoc.id,
                  uid: subVoucherDoc.id,
                }

                subVoucherData.push(subVoucher)

                if (subVoucherLoop === totalSubVoucher) {
                  vouchersDataFilter.push({
                    ...voucher,
                    subVouchers: subVoucherData,
                  })
                }
              })

              if (countLoop === totalDocs) {
                setVouchersLoading(false)
                setVouchers(vouchersDataFilter)
              }
            } else {
              vouchersDataFilter.push({
                ...voucher,
                subVouchers: [],
              })

              if (countLoop === totalDocs) {
                setVouchersLoading(false)
                setVouchers(vouchersDataFilter)
              }
            }
          }
        })
      } catch (error) {
        console.log('error fetching vouchers by service Data') // eslint-disable-line
        console.log(error) // eslint-disable-line
        return false
      }
    },
    [organizationId],
  )

  const inputQueryWhere = (whereArray, key, value) => {
    const endValue = value.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1))
    whereArray.push(where(key, '<=', endValue), where(key, '>=', value))

    return
  }

  return {
    fetchVouchersFilter,
  }
}

export default useVouchersFilter
